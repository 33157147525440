import * as React from "react";
import {Ref} from "react";
import {DataGridExposed} from "../grid/DataGrid";
import {IDClass} from "../../model/CommonTypes";
import {GenericMap} from "../../../index.d";
import {CodeBookController, CodeBookControllerNew} from "../controller/CodeBookController";
import {CodeBookControllerExposed, Props} from "../controller/CodeBookController.d";
import {exist} from "../../../common/utils/Util";

export function Dial<T extends IDClass, Filter = GenericMap, D extends IDClass = T>(props:Props<T, Filter, D>&{dialRef?:Ref<CodeBookControllerExposed>, gridRef?:Ref<DataGridExposed<T,  Filter>> }){
    if (props?.config?.exportConfig && props?.filterClazz) props.config.exportConfig.filterClazz = props.filterClazz;
    return exist(props.mode) ? <CodeBookControllerNew<T, Filter, D> {...props} /> : <CodeBookController<T, Filter, D> {...props} />;
}
