import React, {MutableRefObject, useImperativeHandle, useRef, useState} from "react";
import {
    Button, Grid, IconButton,
    PropTypes, Theme,
    Toolbar,
    Tooltip,
    Typography, useMediaQuery,
} from "@material-ui/core";
import {MultiColumnToolbarAction, ToolbarAction, ToolbarButtonAction} from "./DataGrid.d";
import {GenericMap} from "../../../index.d";
import {useStyleContext} from "../../context/ThemeModeContext";
import {useTranslation} from "react-i18next";
import {MTPaginationExposed} from "./MTPagination";
import DataStorage from "../../../common/DataStorage";
import {useAppContext} from "../../context/AppContext";
import "jspdf-autotable";
import {ExportConfig} from "./MTExportContainer";
import {ColumnsButton} from "./MTColumns";
import {MTMultiColumnActionButtonsExposed, MultiColumnActionButtons} from "./MTMultiColumnAction";
import {DataTemplateToolbarButtons} from "../DataTemplate";
import {makeStyles} from "@material-ui/core/styles";
import {FormInputType} from "../form/Form";
import {StandaloneField} from "../../../common/component/form/StandaloneField";

export type MTToolbarExposed = {
    reload: () => void
    dataChanged: (isEqual: boolean) => void
    selectedCountChanged:(count: number) => void
}

export type ConnectedCols = {
    cols: string[]
}

export type MTToolbarProps = {
    title:string
    customActions:ToolbarAction[]
    getCustomMultiColumnActions: (showExportSwitch: (state?: boolean) => void) => MultiColumnToolbarAction<any>[]
    componentRef?: MutableRefObject<MTPaginationExposed>
    onResetOrder?: () => void
    showExport?: (state?: boolean) => void
    datagridId?: string
    draggable?: boolean
    exportConfig?: ExportConfig
    getHiddenCols?: () => string[]
    showSelect?: boolean
    connectedCols?: ConnectedCols[]
    getFilter?: () => any
    requiredColumns?: string[]
    defaultHiddenColumns?: string[]
    templatesEnabled?: boolean
}&GenericMap;

const useToolbarStyles = makeStyles((theme) => ({
    button: {
        padding: "2px 4px",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "normal",
        height: "35px",
        width: "min-content",
    },
    icon: {
        marginRight: 5,
        display: "flex",
        justifyContent: "center",
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    toolbarButtons: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "3px",
        justifyContent: "flex-end",
        flexGrow: 1,
    },
    title: {
        display: "flex",
        flexGrow: 50,
        alignContent: "center",
        flexWrap: "wrap",
    }
}))
export const MTToolbarButton = (props: ToolbarButtonAction) => {
    const classes = useToolbarStyles();
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

    return isDesktop ? <Button key={props.tooltip} disabled={props.disabled} className={classes.button} onClick={props.onClick} color={props.color ?? "primary"} variant="contained">
        <span className={classes.icon}>{props.icon}</span> {props.tooltip}
    </Button>
    : <Tooltip title={props.tooltip}>
            <IconButton
                style={{padding:"6px"}}
                color="inherit"
                aria-label={props.tooltip}
                onClick={props.onClick}
            >
                {props.icon}
            </IconButton>
    </Tooltip>;
}

export const getActions = (customActions:any[], selectedRows: any, color?: PropTypes.Color, disabled?: boolean) => {
    return <>
        {customActions.map(data => <MTToolbarButton
            key={data.tooltip}
            icon={data.icon()}
            disabled={disabled}
            color={color}
            tooltip={data.tooltip}
            onClick={(e) => data.onClick(e, selectedRows)}
        />)}
    </>
}

export const MTToolbar = (props:MTToolbarProps) => {
    const {classes} = useStyleContext();
    const toolbarClasses = useToolbarStyles();
    const {t} = useTranslation();

    const [r, setReload] = useState(false);
    const [isDataChanged, setIsDataChanged] = useState(null);
    const exportRef = useRef<MTMultiColumnActionButtonsExposed>(null);

    useImperativeHandle(props.componentRef, () => ({
        reload,
        dataChanged,
        selectedCountChanged
    }));

    const reload = () => {
        setReload(!r);
    }

    const dataChanged = (isEqual: boolean) => {
        setIsDataChanged(!isEqual);
    }

    const selectedCountChanged = (count?: number) => {
        exportRef.current?.selectedCountChanged(count);
    }

    return (
        <Toolbar variant={"dense"} style={{height: "min-content", padding: "6px 24px"}}>
            <div className={toolbarClasses.toolbar}>
                <div className={toolbarClasses.title}>
                    <Grid container alignItems={"center"} lg={8} xs={8} sm={6}>
                        <Typography variant='h6' className={classes.themeText} style={{
                            whiteSpace: 'nowrap', textOverflow: 'ellipsis', padding: "2px 0", marginRight: "10px",
                        }}>{props.title}</Typography>
                        {props.filters?.prejezdOdWaypoint && <Grid item lg={4} md={10} sm={12} xs={12}>
                            <StandaloneField variant={"outlined"}
                                             value={props.filters?.prejezdOdWaypoint?.nazevMista}
                                             type={FormInputType.Text}
                                             title={t("Preprava.okoliOdkud")}
                                             disabled={true}
                            />
                        </Grid>}
                        {props.filters?.prejezdKamWaypoint && <Grid item lg={4} md={10} sm={12} xs={12}>
                            <StandaloneField variant={"outlined"}
                                             value={props.filters?.prejezdKamWaypoint?.nazevMista}
                                             type={FormInputType.Text}
                                             title={t("Preprava.okoliKam")}
                                             disabled={true}
                            />
                        </Grid>}
                    </Grid>
                    <div className={toolbarClasses.toolbarButtons}>
                        {!props.showSelect && getActions(props.customActions || [], props.selectedRows)}
                        {!props.showSelect && props.templatesEnabled ? <DataTemplateToolbarButtons/> : null}
                        {!isDataChanged ?
                            <>
                                {!props.showSelect && <>
                                    <ResetButton {...props} />
                                    <ColumnsButton {...props} />
                                </>}
                                <MultiColumnActionButtons actions={props.customMultiColumnActions || []} {...props}
                                                          selectActionsRef={exportRef}/>
                            </>
                            : null}
                    </div>
                </div>
            </div>
        </Toolbar>
    );
}

const ResetButton = (props:MTToolbarProps) => {
    const {t} = useTranslation();
    const {checkDataChanged} = useAppContext();

    const colsOrderOrVisibleChanged = () => {
        const storageKey = `datagrid_${props.datagridId}`;
        let sessionData = DataStorage.get(storageKey, true, "session");
        const session = JSON.parse(sessionData);
        const {defaultHiddenColumns} = props;
        if (session) {
            if (session.columnsOrder && session.columnsOrder.length !== 0) return true;

            if (session.hiddenColumns){
                if (defaultHiddenColumns) {
                    if (!defaultHiddenColumns.every((hc: string) => session.hiddenColumns.includes(hc))) return true;
                    if (session.hiddenColumns.filter((hc: string) => !defaultHiddenColumns.includes(hc)).length !== 0) return true;
                } else {
                    if (session.hiddenColumns.length !== 0) return true;
                }
            }
        }
        return false;
    }

    return colsOrderOrVisibleChanged() && props.draggable ?
        <MTToolbarButton
            icon={<props.icons.Retry />}
            tooltip={t("MaterialTable.resetColumnOrder")}
            onClick={() =>
                checkDataChanged(() => {
                    props.onResetOrder && props.onResetOrder();
                })
            }
        /> : null
}
