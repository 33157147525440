import {CustomFieldComponentProps} from "./FormFieldInterface";
import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {FormVicinityAddress} from "./FormVicinityAddress";
import {FormVicinity} from "./FormVicinity";
import {Grid, IconButton, Tooltip} from "@mui/material";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {NumberProps} from "./FormNumber";
import {JsonIgnore, JsonProperty} from "../../utils/objectmapper/Mapper";
import {Geometry} from "../../../web/model/Geometry";
import {
    useCodeBookControllerContext,
} from "../../../web/raal_components/controller/CodeBookController";
import {useDidMount, useMountedEffect} from "../hooks/SharedHooks";
import {useTranslation} from "react-i18next";
import {exist} from "../../utils/Util";
import {useAppContext} from "../../../web/context/AppContext";

export type VicinityOptions = {
    numberProps?: NumberProps
    titleOkoli?: string
    titleRadius?: string
    titleMpz?: string
    titlePsc?: string
    titleNazevMista?: string
    flexDirection?: "column" | "row"
    verticalSpace?: number
    countryCodeForCompare?: () => boolean
    onChange?: (data: Vicinity) => void
    showTitle?: boolean
    filterForm?: boolean
}

export class Vicinity {
    nazevMista?: string
    countryCode?: string
    @JsonProperty({type: {clazz: Geometry}})
    koordinat?: Geometry
    radius?: number
    psc?: string[]
    mpz?: string[]
    @JsonIgnore({ignoreSet: false, ignoreGet: false})
    osmPlace?: string
}


export type FormVicinityExposed = {
    selectFirstField?: () => void
    onChange?: (v?: Vicinity) => void
}

export type ChildComponentProps = {
    componentRef?: MutableRefObject<FormVicinityExposed>
    getComponentState: () => boolean
}
/**
 * TODO stejná logika jako New, nutné nahradit všude novou novou
 * @param props
 * @constructor
 */
export const FormVicinityContainer = (props: CustomFieldComponentProps<Vicinity, VicinityOptions>) => {
    const vicinityAddressRef = useRef<FormVicinityExposed>();
    const vicinityRef = useRef<FormVicinityExposed>();
    const [showAddress, setShowAddress] = useState(null);
    const {dataGridRef} = useCodeBookControllerContext();
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const {user} = useAppContext();
    const {t} = useTranslation();

    useEffect(() => {
        if (showAddress) {
            vicinityAddressRef.current?.selectFirstField();
        } else if (!showAddress) {
            vicinityRef.current?.selectFirstField();
        }
        dataGridRef.current?.onRefreshFilterHeaderIndexes();
    }, [showAddress, dataGridRef])

    useDidMount(() => {
        if (props.value) setShowAddress(!exist(props.value?.koordinat));
    })

    useMountedEffect(() => {
        if (props.value && options?.filterForm) setShowAddress(!exist(props.value?.koordinat));
    }, [props.value])

    const switchView = (state: boolean) => {
        setTimeout(() => setShowAddress(state), 1);
    }

    return <Grid container alignItems={"center"} style={{minWidth: 200}}>
        <Grid item lg={10} sm={10} xs={10}>
            {showAddress === false && user.canShowKmFeature() ?
                <FormVicinity {...props} getComponentState={() => !showAddress} componentRef={vicinityRef}/> :
                <FormVicinityAddress {...props} getComponentState={() => showAddress ?? true}
                                     componentRef={vicinityAddressRef}/>}
        </Grid>
        <Grid item container lg={2} sm={2} xs={2} justifyContent={"center"}>
            <Tooltip
                title={user.canShowKmFeature() ? showAddress === false ? t('FormLocalization.Vicinity.Address') : t('FormLocalization.Vicinity.Range') : t('FormLocalization.Vicinity.NoKilom')}>
                    <span>
                        <IconButton size={"small"} color={"default"} disabled={!user.canShowKmFeature()}
                                    onClick={() => switchView(showAddress === null ? false : !showAddress)}>
                            <CompareArrowsIcon/>
                        </IconButton>
                    </span>
            </Tooltip>
        </Grid>
    </Grid>
}

// Dočasná komponenta pro refactor
export const FormVicinityContainerNew = (props: CustomFieldComponentProps<Vicinity, VicinityOptions>) => {
    const vicinityAddressRef = useRef<FormVicinityExposed>();
    const vicinityRef = useRef<FormVicinityExposed>();
    const [showAddress, setShowAddress] = useState(null);
    // const {dataGridRef} = useCodeBookControllerContextNew();
    const {user} = useAppContext();
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const {t} = useTranslation();

    useEffect(() => {
        if (showAddress) {
            vicinityAddressRef.current?.selectFirstField();
        } else if (!showAddress) {
            vicinityRef.current?.selectFirstField();
        }
    }, [showAddress])

    // useEffect(() => {
    //     if (showAddress) {
    //         vicinityAddressRef.current?.selectFirstField();
    //     } else if (!showAddress) {
    //         vicinityRef.current?.selectFirstField();
    //     }
    //     dataGridRef.current?.onRefreshFilterHeaderIndexes();
    // }, [showAddress, dataGridRef])

    // Prejezdy :
    // Pri zadani prvotneho filtra zobraz MPZ/PSC
    // Pri zadani MPZ/PSC v riadku filter ostan pri MPZ/PSC
    // Prepravy ostavaju
    useDidMount(() => {
        if (props.isPrejezd) {
            if (props.value?.koordinat) {
                if (props.value) setShowAddress(exist(props.value?.koordinat));
            } else {
                if (props.value) setShowAddress(!exist(props.value?.koordinat));
            }
        } else {
            setShowAddress(!exist(props.value?.koordinat))
        }
    })

    // Zakomentovane v 4616 - spravanie ohandlovane v useDidMount
    // useMountedEffect(() => {
    //     if (props.value && options?.filterForm) setShowAddress(exist(props.value?.koordinat) && props.isPrejezd);
    // }, [props.value])

    const switchView = (state: boolean) => {
        setTimeout(() => setShowAddress(state), 1);
    }

    return <Grid container alignItems={"center"} style={{minWidth: 200}}>
        <Grid item lg={10} sm={10} xs={10}>
            {showAddress === false && user.canShowKmFeature() ?
                <FormVicinity {...props} getComponentState={() => !showAddress} componentRef={vicinityRef}/> :
                <FormVicinityAddress {...props} getComponentState={() => showAddress ?? true}
                                     componentRef={vicinityAddressRef}/>}
        </Grid>
        <Grid item container lg={2} sm={2} xs={2} justifyContent={"center"}>
            <Tooltip
                title={user.canShowKmFeature() ? showAddress === false ? t('FormLocalization.Vicinity.Address') : t('FormLocalization.Vicinity.Range') : t('FormLocalization.Vicinity.NoKilom')}>
                    <span>
                        <IconButton size={"small"} color={"default"} disabled={!user.canShowKmFeature()}
                                    onClick={() => switchView(showAddress === null ? false : !showAddress)}>
                            <CompareArrowsIcon/>
                        </IconButton>
                    </span>
            </Tooltip>
        </Grid>
    </Grid>
}
