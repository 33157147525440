import * as React from "react";
import {CSSProperties, MutableRefObject, ReactElement, ReactNode} from "react";
import {GenericMap} from "../../../index.d";
import {FieldError} from "./ValidationError";
import {FormFieldComponent, FormFieldExposed} from "../../../web/raal_components/form/FormField";

export interface FormFieldInterface {

}
export type DataHolder = {
    focused?:boolean;
    supportTabFocus:number;
    currentTabFocused:number;
    disabled?:boolean;
    hasError?:boolean;
}
export class FormFieldListeners {
    onLabelClick:(e:React.MouseEvent)=>void
}

export interface FormFieldInterfaceProps<T, Data=any> {
    name:string,
    value?:T,
    disabled?:boolean,
    className?:string,
    inputClassName?: string,
    defaultValue?: any,
    placeholder?:string,
    simpleLabel?:boolean,
    dataTip?:string,
    //in hooks wrap this to useCallback
    onValueChanged:(value:any)=>void,
    listeners?:FormFieldListeners,
    focused?:boolean,
    enableFocusSupport?:(countAs?:number, current?:number)=>void,
    title?:ReactNode,
    onKeyDown?:(e:React.KeyboardEvent<HTMLInputElement>)=>void,
    onKeyUp?:(e:React.KeyboardEvent<HTMLInputElement>)=>void,
    error?:string,
    required?:boolean,
    onFocus?:(userfocus:boolean, index?:number)=>void,
    variant?: 'standard'|'outlined'|'filled',
    style?:CSSProperties,
    onBlur?:()=>void,
    showAdornment?:boolean,
    startAdornment?: ReactElement,
    onError:(e:string[])=>void,
    autofillValue?:boolean,
    currentFocused?:number,
    containerFocused?:boolean,
    allErrors?:FieldError[],
    focusedIndex?:number,
    data?:Data,
    onInteractStart?:()=>void,
    onInteractEnd?:()=>void,
    onDisableActionKeys?:(isDisabled: boolean, focusNext?: boolean)=>void,
    autoSelectFirstValueOnTab?: boolean,
    onFocusNextField?:(focusFirstOrButtons?: boolean)=>void,
    onFocusPrevField?: (index?: number) => void
    onFocusButton?:()=>void
    setLastField?:(isLast?: boolean)=>void,
    isFirstInTab?: number,
    isLastInTab?: boolean,
    isFormGrid?: boolean
    formField?: FormFieldComponent
    isTabbedContainer?: boolean
    resetFormFocus?:()=>void
    fieldRef?: MutableRefObject<FormFieldExposed>
}

export interface CustomFieldComponentProps<T, O> extends FormFieldInterfaceProps<T> {
    options?:O | (() => O)
    dataHolder?:MutableRefObject<DataHolder>
    errorKey?:string
    isPrejezd?:boolean
}

export class CustomFieldComponent<T = string, O = GenericMap, S = any, SS = any> extends React.Component<CustomFieldComponentProps<T, O>, S, SS> implements FormFieldInterface {

}

export const cleanName = (name:string)=>{
    if((!name || name.length === 0)) {
       return undefined;
    }
    return name;
};
