import React, {useState} from "react";
import {
    DragDropContext,
    Draggable,
    DraggingStyle,
    Droppable, DropResult,
    NotDraggingStyle,
} from "react-beautiful-dnd";
import ListItem from "@material-ui/core/ListItem";
import {Button, Checkbox, List} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import {dispatchModal, ModalActionType} from "../../../common/component/ModalContainer";
import {useTranslation} from "react-i18next";

export type SelectItem = {
    id: string
    label: string
    enabled: boolean
};

export const useMTExportSelect = () => {
    const {t} = useTranslation();

    const showExportSelect = async(selectItems: SelectItem[]) => {
        return new Promise<SelectItem[]>((resolve) => {
            dispatchModal({
                onClose: () => {
                    resolve(null)
                },
                type: ModalActionType.Show,
                title: t("MaterialTable.exportSelect"),

                body: (<MTExportSelect
                        items={selectItems}
                        onConfirm={(data: SelectItem[]) => {
                            dispatchModal({type: ModalActionType.Hide});
                            resolve(data.filter((item: SelectItem) => item.enabled));
                        }}
                    />
                )
            });
        });
    }

    return {showExportSelect};
}

const MTExportSelect = ({ items: initialItems, onConfirm }: { items: SelectItem[], onConfirm: (items: SelectItem[]) => void }) => {
    const [items, setItems] = useState(initialItems);
    const {t} = useTranslation();

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const reorderedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(reorderedItems);
    };

    const handleConfirm = () => {
        onConfirm?.(items);
    };

    const reorder = (list: SelectItem[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleCheckboxChange = (id: string) => {
        setItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, enabled: !item.enabled } : item
            )
        );
    };

    const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle) => ({
        ...draggableStyle,
        padding: 0,
        height: "24px",
        ...(isDragging && {
            background: "rgb(0,0,0,0.1)",
        })
    });

    return (
        <div>
            <div>
                <Button variant="contained" color="primary" onClick={handleConfirm} style={{ width: "100%" }}>
                    {t("MaterialTable.export")}
                </Button>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <List ref={provided.innerRef} style={{height: "500px", overflowY: "scroll", marginTop: "20px"}}>
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <ListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <Checkbox
                                                id={item.id}
                                                aria-label={item.label}
                                                checked={item.enabled}
                                                onChange={() => handleCheckboxChange(item.id)}
                                            />
                                            <ListItemText primary={item.label} />
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};