import {ReactToPrint} from "react-to-print";
import React, {useEffect, useRef} from "react";

export type PrintTable = {
    columns: string[],
    data: string[][]
};

export const MTPrintTable = (props: {
    data: PrintTable
}) => {
    const componentRef = useRef();
    const printRef = useRef(null);

    useEffect(() => {
        if(props.data  && printRef) {
            printRef?.current.handleClick();
        }
    });

    return <div style={{
        position: "absolute",
        display: "none",
        top: 0,
        left: 0,
        backgroundColor: "white",
        color: "black"
    }}>
        {props.data && <div ref={componentRef} style={{color: "black"}}>
            <table style={{border: "1px solid black", borderCollapse: "collapse", textAlign: "center"}} >
                <thead>
                <tr>
                    {props.data.columns.map((column, index) => (
                        <th style={{border: "1px solid black"}} key={index}>{column}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {props.data.data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <td style={{border: "1px solid black"}} key={cellIndex}>{cell}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>}
        {
            props.data && <ReactToPrint
                trigger={() => <React.Fragment/>}
                content={() => componentRef.current}
                ref={printRef}
            />
        }
    </div>
}