const en = {
  "MobileApp": {
    "AppLoading": {
      "CompanyInfo": "The first database of loads and available cars since 1992."
    },
    "Buttons": {
      "Contact": "Contact"
    },
    "Login": {
      "InvalidCredentials": "Invalid login details"
    },
    "ConfigureParameters": {
      "NavigationTitle": "Configure view",
      "SectionTitle": "Select the data to display on the offer card"
    },
    "SortParameters": {
      "NavigationTitle": "Sort list by",
      "SortSectionTitle": "Choose sort direction:",
      "ParameterSectionTitle": "Choose the parameter by which to sort the list:",
      "Descending": "Descending",
      "Ascending": "Ascending"
    },
    "FilterParameters": {
      "CancelFilters": "Cancel filters",
      "Filter": "Filter"
    },
    "Cargos": {
      "FilterCargos": "Filter cargos",
      "AdditionalInfo": "Additional cargo information",
      "ChooseKinds": "Choose kinds",
      "All": "All",
      "NewCargo": "New cargo",
      "EditCargo": "Edit cargo",
      "FillBasicInfo": "Fill in the basic information",
      "CargoDetail": "Cargo detail",
      "Distance": "Distance"
    },
    "Lorries": {
      "FilterLorry": "Filter lorries",
      "NewLorry": "New lorry",
      "FillBasicInfo": "Fill in the basic information",
      "AdditionalInfo": "Additional lorry information",
      "LorryDetail": "Lorry detail",
      "EditCargo": "Edit lorry"
    },
    "Advertising": {
      "FilterAdvertising": "Filter advertising",
      "NewAdvertising": "New ad",
      "EditAdvertising": "Edit ad",
      "AdditionalInfo": "Additional information",
      "FillBasicInfo": "Fill in the basic information",
      "AdvertisingDetail": "Ad detail"
    },
    "Premises": {
      "FilterPremises": "Filter business premises",
      "StreetNumber": "Street + House Number",
      "Registration": "Registration",
      "ContractDate": "Contract/in RAAL from",
      "DetailInfo": "Detailed information",
      "Accountants": "Accountant"
    },
    "Tabs": {
      "Settings": "Settings",
      "Cargos": "Cargos",
      "Lorries": "Lorries",
      "Advertising": "Advertising"
    },
    "Settings": {
      "AppLanguage": "Language",
      "AppAppearance": "Appearance",
      "AppSupport": "Support",
      "AppSupportDescription": "In case of difficulties, please do not hesitate to contact us by phone or email.",
      "AppSupportHtmlContent": "<p>email.: <a href=\"mailto:support@raal.cz\" target=\"_self\">support@raal.cz</a>&nbsp;</p>                                                                                                                                <p>mob.: +420 604 105 758</p>                                                                                                                                <p>tel.: +420 495 217 281</p>                                                                                                                                <p>tel.: +420 495 217 282</p>",
      "AppVersion": "Version {0}",
      "ChooseAppLanguage": "Select the language of the app",
      "ChooseAppAppearance": "Select the appearance of the app",
      "DarkMode": "Dark",
      "LightMode": "Light",
      "SystemMode": "Same as device"
    }
  },
  "AppTitle": "RAALTRANS",
  "AppActivityType": {
    "View": "Display"
  },
  "AppActivitySubType": {
    "Detail": "Detail",
    "Row": "Line"
  },
  "AutoRefresh": {
    "true": "Automatic refresh on (CTRL+Space bar)",
    "false": "Automatic refresh off (CTRL+Space bar)",
    "title": "Auto-refresh",
    "NewData": "New data"
  },
  "RefreshAlert": {
    "TooltipOn": "Sound notification for new offers ON",
    "TooltipOff": "Sound notification for new offers OFF",
    "TooltipInactive": "Sound notification PAUSED (some action on the page is required to resume)"
  },
  "Buttons": {
    "Rejstriky": "Registers",
    "MakeInvalid": "Invalidate",
    "MakeValid": "Renew",
    "Help": "Help",
    "Login": "Login",
    "Logout": "Logout",
    "Save": "Save",
    "SaveAsNew": "Save as new",
    "SaveAsCurrent": "Save as current",
    "Delete": "Delete",
    "Refresh": "Refresh",
    "AddNewRecord": "Add new record",
    "Cancel": "Cancel",
    "Confirm": "Confirm",
    "Add": "Add",
    "ReloadWait": "Reload again with longer waiting",
    "Close": "Close",
    "Continue": "Continue",
    "Cancellation": "Cancellation",
    "Agree": "I agree",
    "Check": "Check"
  },
  "ConfirmDialog": {
    "Title": "Confirmation",
    "Body": "Carry out action?"
  },
  "DataChangedDialog": {
    "Title": "Unfinished form",
    "Body": "There is an unfinished form, would you like to give up action?\nAll unfinished data will be lost."
  },
  "Currency": {
    "Title": "Currency",
    "name": "Name",
    "currencyCode": "Code",
    "kurz": "Exchange rate (CZK)",
    "datum": "Synchronized"
  },
  "DataContextChanged": {
    "title": "The data has changed. Load new data by pressing F5, or click on the button.\nNotification: All unfinished data will be lost."
  },
  "Default": {
    "Id": "ID",
    "Enabled": "Enabled",
    "Telefon": "Telephone",
    "QuickContact": "Quick contact",
    "PhoneNumbers": "Telephone number",
    "PhoneNumbersKratke": "Tel. num.",
    "FillCorrectPhone": "Fill in correct telephone number",
    "Email": "Email",
    "Polozka": "Item",
    "Text": "Text",
    "Key": "Key",
    "Note": "Note",
    "NoteAdmin": "Admin note",
    "NoteMaster": "Master note",
    "Slovo": "Word",
    "Name": "Name",
    "DataName": "Name",
    "Map": "Map",
    "Mpz": "ICC",
    "MapDefine": "Enter coordinates",
    "Loading": "Loading",
    "ModalZakladni": "Record detail",
    "TabZakladni": "Basic data",
    "region": "Type of request",
    "typ": "Type of request",
    "typLicence": "Road scheme",
    "Datum": "Date",
    "DatCas": "Zeitpunkt der letzten Änderung",
    "Jazyky": "Languages",
    "Yes": "Yes",
    "No": "No",
    "Or": "Or",
    "LoadingDetail": "Loading detail",
    "LoadingDetailFailed": "Loading detail failed",
    "NotFound": "Record was not found",
    "DeleteText": "Do you want to delete this record?",
    "DeleteTitle": "Delete record",
    "Account": "Users accounts",
    "AccountAdd": "add account",
    "UnlockText": "Do you want to unlock the \n detail on this tab?",
    "UnlockTitle": "Unlock record",
    "PdfHeader": "Offer {0} telephone {1} and its author",
    "PdfFirma": "Complete information about the place of business {0}",
    "PdfExportedUser": "Exported by",
    "PdfExportedDate": "Date",
    "SendTheFirstAccessDataTextSingle": "This action is primarily intended for premises that have only one user (SINGLE). ",
    "SendTheFirstAccessDataTitleSingle": "Sending the first access data SINGLE",
    "SendTheFirstAccessDataButtonSingle": "SEND THE FIRST ACCESS DATA SINGLE",
    "SendTheFirstAccessDataTextMulti": "This action is primarily intended for premises that have multiple users. ",
    "SendTheFirstAccessDataTitleMulti": "Sending the first access data MULTI-USER LICENCE",
    "SendTheFirstAccessDataButtonMulti": "SEND THE FIRST ACCESS DATA MULTI-USER LICENCE",
    "Jednotka": "Unit",
    "JednotkaShort": "UoM"
  },
  "Dials": {
    "Users": "Users",
    "Companies": "Companies",
    "Places": "Business premises",
    "Countries": "Countries",
    "Currencies": "Currencies and exchange rates",
    "Types": "Types",
    "Params": "Parameters",
    "Jobs": "Jobs",
    "ZakazanaSlova": "Words not allowed",
    "NevhodnaSlovaFirma": "Controlled words u firem",
    "HlidanaSlova": "Controlled words",
    "VolneVozy": "Free lorries",
    "Dispeceri": "Dispatchers",
    "Prepravy": "Cargos",
    "Advertising": "Advertising",
    "Messages": "Messages",
    "ThunderMessages": "Flash messages",
    "HlidanaSlovaKonfiguraceFirem": "Configuration of companies",
    "Pvi": "Own CFA",
    "PviShort": "CFA",
    "Firma": "Company",
    "Provozovna": "Business premises",
    "HistoryPrepravy": "Cargos",
    "HistoryAdvertising": "Advertising",
    "HistoryVolneVozy": "Free lorries",
    "Kilometrovnik": "Places",
    "ProfilyVozidel": "List of lorries",
    "Trasy": "Routes",
    "PrepravaTrasa": "Route - Cargo",
    "VozidloTrasa": "Route - Free lorry",
    "KilometrovnikHlaseni": "Error notification",
    "KilometrovnikHlaseniAdmin": "Announced errors",
    "PrejezdyPreprav": "Cargo passages",
    "PrejezdyVozidel": "Free lorries passages",
    "Dokladky": "Additional loads",
    "DiagPage": "Diagnostics",
    "Notes": "Notes",
    "NepohodlneOsoby": "Troublesome persons",
    "Pripady": "Cases",
    "Nadpripady": "Emergency cases",
    "Faktury": "Invoices",
    "ReportTexts": "Text translation",
    "TriADost": "3x and enough",
    "Smlouvy": "Contracts"
  },
  "DialDefaults": {
    "CreateRecord": "Create a record",
    "CreateRecordAndNext": "Create and next",
    "CreateRecordAsNew": "Create a record as new",
    "UpdateRecord": "Save changes",
    "DeleteRecord": "Delete",
    "ClearField": "Clear",
    "UnlockRecord": "Unlock record",
    "ServerError": "Server error",
    "ServerErrorMessage": "Unexpected server error",
    "ServerTimeoutMessage": "Time is up"
  },
  "Prejezdy": {
    "odkud": "Passage from",
    "kam": "Passage to",
    "nabidkaOdkud": "Offer - from",
    "nabidkaKam": "Offer - to",
    "radiusNabidkaOdkud": "Offer - from [km]",
    "radiusNabidkaKam": "Offer - to [km]",
    "prejezdy": "Passages",
    "volneVozy": "Free lorries",
    "prepravy": "Cargos",
    "prejezd": "With passage",
    "prejezdOnly": "Passage only",
    "original": "Original",
    "found": "Found",
    "prejezdCelkem": "P-Total",
    "prejezdOd": "P-From",
    "prejezdKam": "P-To",
    "radiusPrejezd": "Total maximum passage [km]",
    "radiusPrejezdOd": "Maximum passage from [km]",
    "radiusPrejezdKam": "Maximum passage to [km]",
    "kilometrovnikPrejezd": "Road scheme passage",
    "vyhledaniPrepravy": "Cargos, cargo searching",
    "vyhledaniVozu": "Passages, free lorry searching",
    "calculate": "Calculate passage",
    "Preprava": "Default cargo",
    "Vozidlo": "Default lorry",
    "trasa": "Route",
    "vozidlo": "Lorry",
    "max": "Maximum passage",
    "info": "Help",
    "datum": "Date",
    "datumOd": "Date from",
    "datumDo": "Date to"
  },
  "Dispecer": {
    "Username": "Dispatecher´s name",
    "DatCas": "Date of last change"
  },
  "Enumerations": {
    "CiselnikTyp": {
      "I": "A_Advertising",
      "P": "C_Cargos",
      "V": "F_Free_lorries",
      "R": "K_Kind",
      "D": "A_Addition",
      "G": "R_Register of companies",
      "S": "S_State"
    },
    "Druh": {
      "1": "Tarpaulin",
      "2": "Plateau",
      "3": "Tilting truck",
      "4": "Box",
      "5": "Frigo truck",
      "6": "ISO truck",
      "7": "Tautliner",
      "8": "Hanging clothing box",
      "9": "Tank",
      "10": "Coil",
      "11": "Walkingfloor",
      "12": "Double decker",
      "13": "Low loader",
      "14": "Flatbed",
      "15": "Container chassis",
      "16": "Stanchions",
      "17": "Mega",
      "18": "Jumbo",
      "19": "Solo",
      "20": "Van-tarpaulin",
      "21": "Van-box",
      "22": "Pick-up",
      "23": "Car transporter",
      "250": "Another"
    },
    "Duvod": {
      "NABIDKA": "Offer",
      "POPTAVKA": "Demand"
    },
    "InvalDuvodPreprava": {
      "0": "OK",
      "1": "Date in past already",
      "2": "Your offer includes banned words",
      "3": "Invalidated by operator",
      "4": "User is not allowed to publish cargos",
      "5": "Business premises suspended",
      "6": "Date is in too distant future",
      "7": "Broad range between the dates from-to",
      "8": "Includes telephone number that is not in your contact details",
      "9": "Duplicity",
      "10": "User suspended",
      "11": "User without phone",
      "12": "Removal of rights by the admin",
      "OK": "OK",
      "NEZNAME_TELC": "Unknown telephone number"
    },
    "InvalDuvodVozidlo": {
      "0": "OK",
      "1": "Date in past already",
      "2": "Your offer includes banned words",
      "3": "Invalidated by operator",
      "4": "User is not allowed to publish free lorries",
      "5": "Business premises suspended",
      "6": "Date is in too distant future",
      "7": "Broad range between the dates from-to",
      "8": "Includes telephone number that is not in your contact details",
      "9": "Duplicity",
      "10": "User suspended",
      "11": "User without phone",
      "12": "Removal of rights by the admin",
      "OK": "OK",
      "NEZNAME_TELC": "Unknown telephone number"
    },
    "InvalidDuvodInzerat": {
      "0": "OK",
      "1": "Advertisement is too old",
      "2": "Your advertisement includes banned words",
      "3": "Invalidated by operator",
      "4": "User is not allowed to publish advertisements",
      "5": "Business premises suspended",
      "8": "Includes telephone number that is not in your contact details",
      "9": "Duplicity",
      "10": "User suspended",
      "11": "User without phone",
      "12": "Removal of rights by the admin",
      "OK": "OK",
      "INZERAT_ZNEPLATNEN": "Advertisement invalidated"
    },
    "InvoiceFrequency": {
      "0": "Monthly",
      "1": "Quarterly",
      "2": "Semi-annualy",
      "3": "Annualy",
      "MONTHLY": "Monthly",
      "QUARTERLY": "Quarterly",
      "HALFYEARLY": "Semi-annualy",
      "YEARLY": "Annualy"
    },
    "KlasifikaceColor": {
      "0": "None",
      "1": "Green",
      "2": "Orange",
      "3": "Red"
    },
    "KlasifikaceValue": {
      "0": "None",
      "1": "1",
      "2": "2",
      "3": "3"
    },
    "LicenceTyp": {
      "1": "No",
      "2": "Yes",
      "WITHKM": "Yes",
      "WITHOUTKM": "No"
    },
    "Podstav": {
      "0": "Without status",
      "1": "Action 3x and enough",
      "2": "Payments",
      "3": "Own request",
      "4": "Unpaied SW",
      "5": "Break of the contract",
      "6": "Termination",
      "7": "Problem",
      "8": "Original documents",
      "BEZ_PODSTAVU": "Without status",
      "X3_A_DOST": "Action 3x and enough",
      "PLATBY": "Payments",
      "POZASTAVEN_VLASTNI_ZADOST": "Own request",
      "PODEZRELY": "Unpaied SW",
      "PORUSENI_SMLOUVY": "Break of the contract",
      "VYPOVED": "Termination",
      "PROBLEM": "Problem",
      "ORIGINAL": "Original documents"
    },
    "Predmet": {
      "NAKLADNI_AUTA": "Lorries",
      "OSOBNI_AUTA": "Motorcars",
      "PRIVESY_NAVESY": "Trailers/Semitrailers",
      "NAHRADNI_DILY": "Spare parts",
      "JINE": "Another"
    },
    "Region": {
      "0": "CZ",
      "1": "EU",
      "2": "GLOBAL",
      "3": "SK",
      "4": "CZSK"
    },
    "Roles": {
      "ROLE_ADMIN": "Admin",
      "ROLE_MASTER": "Master",
      "ROLE_USER": "User",
      "ROLE_NONE": "No role"
    },
    "TypOdesilatele": {
      "FYZICKA_OSOBA": "natural person",
      "PRAVNICKA_OSOBA": "legal person",
      "SLOVENSKO": "SK (Slovakia)"
    },
    "SmlouvaS": {
      "NONE": "Without contract",
      "FOHK": "FO HK",
      "FONITRA": "FO Nitra",
      "RAALHK": "RAALTRANS a.s. HK",
      "RAALNITRA": "RAALTRANS a.s. Nitra",
      "RAALHKNOVA": "Nová RAALTRANS a.s. HK",
      "FONITRANOVA": "Nová FO Nitra",
      "FOHKNOVA": "Nová FO HK",
      "RAAL_AS_2023": "RAALTRANS a.s. 2023",
      "RAAL_AS_2024": "RAALTRANS a.s. 2024",
      "RAAL_SRO_2023": "RAALTRANS s.r.o 2023",
      "RAAL_SRO_2024": "RAALTRANS s.r.o 2024"
    },
    "Stav": {
      "0": "Active",
      "1": "Suspended",
      "JEDE": "Active",
      "POZASTAVIL": "Suspended"
    },
    "SystemParamKey": {
      "PREPRAVA_DATE_RANGE": "Maximum date range in cargos (in days)",
      "VOZIDLA_DATE_RANGE": "Maximum date range in free lorries (in days)",
      "INZERAT_MAX_AGE": "Maximum age of the advertisement (in days)",
      "PREPRAVY_DRUHY_MAX": "Maximum number of kinds in cargos",
      "VOZIDLA_DRUHY_MAX": "Maximum number of kinds in free lorries",
      "PREPRAVA_DATE_ADVANCE_MAX": "Maximum date ahead in cargos (in days)",
      "VOZIDLO_DATE_ADVANCE_MAX": "Maximum date ahead in free lorries (in days)",
      "PREPRAVA_LENGTH": "Loading meter in cargos",
      "PREPRAVA_WEIGHT": "Weight in cargos",
      "VOZIDLO_LENGTH": "Loading meter in free lorries",
      "VOZIDLO_WEIGHT": "Loading weight in free lorries",
      "PROVOZOVNA_CANCEL_PERIOD": "Settings of indication cancelled after the contract termination (in days)",
      "MAX_OFFER_EDITS": "Maximum number of offer restorations / editations (-1 = unlimited)",
      "OFFER_DELETE_DUPLICATES": "Delete with offer plus duplicity",
      "MAX_CALCULATION_JOBS_PER_NODE": "Maximum number of calculated routes related to one server",
      "UZIVATEL_KONTROLA_PODSTAVU": "List of status of business premises for IP control during user log in",
      "ARCHIVE_RETENTION_PERIOD": "Delete the offers from archive of our offers (in days of input to archive)",
      "VIEW_ARCHIVE_RETENTION_PERIOD": "Delete the offers from archive of displayed offers (in days of input to archive)",
      "ARCHIVE_PERIOD": "Offer transfer to archive of our offers (in days from invalidation)",
      "HIDE_INZERCE": "Hide advertisement",
      "IGNORE_NOTIFICATION_PROVOZOVNA_KOD": "Noncontrolled business premises"
    },
    "TypZpravy": {
      "B": "Flash message",
      "C": "C",
      "I": "I",
      "R": "R",
      "S": "S",
      "T": "Courses",
      "H": "H",
      "V": "Description of the system"
    },
    "RoadType": {
      "0": "Highway",
      "1": "Roadway for motor vehicles",
      "2": "A-roads",
      "3": "B-roads",
      "4": "Roads",
      "5": "Others"
    },
    "TrasaState": {
      "0": "Waiting for calculation",
      "1": "Course of calculation",
      "2": "Calculated",
      "3": "Error"
    },
    "OsrmProfile": {
      "0": "N1 (to 3,5 tuns)",
      "1": "N2 (to 12 tuns)",
      "2": "N3 (to 40 tuns)"
    },
    "RouteCalculationStatus": {
      "0": "OK",
      "1": "Server error",
      "2": "Route not found",
      "3": "Costs can not be calculated",
      "4": "Incomplete configuration - prices of toll, exchange rates, ..."
    },
    "KilometrovnikHlaseniStatus": {
      "0": "New",
      "1": "Closed"
    },
    "DluhNadpripadStav": {
      "0": "Opened",
      "1": "Closed"
    },
    "DluhPripadStav": {
      "0": "Not sent",
      "1": "Not confirmed",
      "2": "Not paied",
      "3": "Closed",
      "4": "Solved without compensation",
      "5": "Closed (termination)",
      "6": "Closed (point 3.9)",
      "7": "Time-barred",
      "8": "Denied"
    },
    "DluhFakturaStav": {
      "0": "Not sent",
      "1": "Not confirmed",
      "2": "Not paied",
      "3": "Paied",
      "4": "Solved without compensation",
      "5": "Not paied (termination)",
      "6": "Not paied (point 3.9)",
      "7": "Time-barred",
      "8": "Denied"
    },
    "ReportLanguage": {
      "cs": "Czech",
      "en": "English",
      "de": "German",
      "pl": "Polish",
      "sk": "Slovak"
    },
    "TypPravniDokumentace": {
      "VP": "General terms and conditions",
      "LP": "License terms",
      "GDPR": "Personal data protection principles",
      "PDI": "Provision of traffic information",
      "VYPCZ": "Termination of contract - CZ",
      "VYPSK": "Termination of contract - SK"
    },
    "LastAction": {
      "PASSWORD_CHANGE": "Password change"
    }
  },
  "ErrorComponent": {
    "title": "Error",
    "homeButton": "Back to previous page",
    "redirectionMessage": "You will be forwarded to the initial page in a moment.",
    "error403": "Access denied."
  },
  "Export": {
    "detailHeader": "Detail"
  },
  "Filter": {
    "Templates": "Search filter",
    "TemplateName": "Filter name",
    "SaveTemplate": "New",
    "UpdateTemplate": "Save",
    "RemoveTemplate": "Delete",
    "SelectTemplate": "Select a search filter",
    "NoTemplates": "No filter found",
    "ClearFilter": "Remove filter",
    "DoFilter": "Search",
    "OpenFilter": "Filters",
    "UseFilter": "Filters",
    "TooltipForFilledFilter": "Filtr is active",
    "TooltipForEmptyFilter": "Filtr is not active",
    "DeleteConfirmationText": "Do you really want to delete the filter?",
    "Vychozi": "Initial",
    "SaveFilter": "Save filter",
    "SavedFilters": "Saved filters"
  },
  "Firma": {
    "Id": "Company ID",
    "Title": "Company",
    "TabProvozovny": "Business premises",
    "TabUzivatele": "Users",
    "TabDispeceri": "Dispatchers",
    "Provozovna": "Company (doce of business premises)",
    "pozastavena": "Cancelled",
    "gdpr": "Consent to the GDPR",
    "UserIdentityLabel": "Company identification",
    "UserLabel": "Company",
    "UserContactLabel": "Registration address",
    "UserNextLabel": "Other information",
    "ContactLabel": "Addresses",
    "NextLabel": "Details",
    "R1Nazev": "Name",
    "R1Ulice": "Street",
    "R1CisloPopisne": "Building number",
    "R1CisloPopisneKratke": "b.n.",
    "R1Obec": "Municipality",
    "R1CastObce": "Part of the municipality",
    "R1Psc": "Post Code",
    "R1Stat": "State",
    "ico": "ID number",
    "dic": "EU VAT / ID VAT",
    "dic1": "VAT number",
    "vznik": "date of establishment",
    "NezadouciZakaznik": "Undesirable client",
    "NezadouciZakaznikShort": "Undesirable",
    "CreateFirmaAndProvozovna": "Create company and business premises",
    "VytvoritFirmu": "Create company",
    "Pojisteni": "Insurance",
    "datPojist": "Date of insurance",
    "ChooseFirma": "Choose a company",
    "FirmaMaSlovo": "It is company´s turn to speak",
    "EnabledOnFirma": "Enabled in company",
    "MissingFirma": "!!! Company is missing !!!",
    "RegDatum": "Date of registration",
    "datSmlouvy": "Date of contract / In RAALu from",
    "raalOd": "In RAAL from",
    "http": "WWW",
    "fPoznamka": "Administration note",
    "DatumZmenyRejstrik": "Date of change in register",
    "PoznamkaRejstrik": "Note register",
    "AktualizaceRejstrik": "Actualization of register",
    "Nadpripad": "Overcase",
    "TooltipRejstrikyVeFirme": "Fill in the state to display register"
  },
  "FormLocalization": {
    "DataSaved": "Record was successfully saved",
    "DataSavedFailed": "Saving of the record failed",
    "DataRemoved": "Record was successfully deleted",
    "DataWasRemoved": "Record was removed",
    "DataWasEdited": "Record was changed",
    "DataRemovedFailed": "Deletion of the record failed",
    "ServerError": "Server error",
    "ValidationError": "Validation error",
    "FieldMessages": {
      "FieldIsRequired": "Field is required",
      "FieldNotNull": "Field not null",
      "FieldIsRequiredValue": "Field is required {0}",
      "CoordinatesMissing": "Coordinates missing {0}",
      "UserLicenceError": "Road scheme does not include chosen address"
    },
    "DateTimePicker": {
      "invalidDate": "Invalid date",
      "cancelLabel": "Cancel",
      "clearLabel": "Clear",
      "emptyLabel": "Empty",
      "invalidLabel": "Incorrect date",
      "okLabel": "Confirm",
      "todayLabel": "Today",
      "minDateMessage": "Date is in the past",
      "minDateTimeMessage": "Date and time can not be in the past",
      "maxDateMessage": "Date is too in future",
      "disablePast": "Date is in the past",
      "shouldDisableDate": "Date is out of allowed scale",
      "disableFuture": "Date is too in the future",
      "maxDate": "Incorrect date",
      "minDate": "Date is in the past"
    },
    "Vicinity": {
      "Address": "Accurately",
      "Range": "Passage",
      "NoKilom": "License with road scheme required"
    }
  },
  "FormEditor": {
    "add": "Add",
    "cancel": "Cancel",
    "colorpicker": "Color choice",
    "text": "Text",
    "background": "Backround",
    "h": "Heading {0}",
    "blockquote": "Paragraph",
    "code": "Code",
    "blocktype": "Styles",
    "normal": "Normal",
    "embedded": "Inserted",
    "embeddedlink": "Inserted link",
    "enterlink": "Open link",
    "emoji": "Emoticon",
    "fontfamily": "Font",
    "fontsize": "Font size",
    "history": "History",
    "undo": "Back",
    "redo": "Again",
    "image": "Insert image",
    "fileUpload": "Upload file",
    "byURL": "URL",
    "dropFileText": "Drag and drop the file here or click to choose",
    "bold": "Bold",
    "italic": "Italics",
    "underline": "Underline",
    "strikethrough": "crossed out",
    "monospace": "Code",
    "superscript": "Upper index",
    "subscript": "Lower index",
    "linkTitle": "Title link",
    "linkTarget": "Target proceeding",
    "linkTargetOption": "Open the link in a new window",
    "link": "Insert link",
    "unlink": "Remove link",
    "list": "List",
    "unordered": "Bullet",
    "ordered": "Numbering",
    "indent": "Enlarge indentation",
    "outdent": "Reduce indentation",
    "remove": "Erase formatting",
    "textalign": "Text align",
    "left": "Align to the left",
    "center": "Align in the centre",
    "right": "Align to the right",
    "justify": "Align to the block"
  },
  "GeneratePassword": {
    "title": "Send new password",
    "tooltip": "The password will be sent to the provided email address.",
    "success": "The password has been sent to the user.",
    "error": "The password generating error."
  },
  "GoogleMaps": {
    "Title": "Google Map",
    "SearchPlaces": "Search"
  },
  "HttpLocalization": {
    "SignedOut": "Signed out",
    "NetworkError": "Network error",
    "Error401FingerPrint": "You were logged out. You logged in different browser or the time is up.",
    "LogoutBySystem": "You were logged out by the system.",
    "Error401": "Log in token expired"
  },
  "Informations": {
    "Info": "Raal info",
    "System": "Description of the system",
    "TollRate": "Toll rate",
    "ExchangeRates": "Exchange rate",
    "ReleaseNotes": "Release notes"
  },
  "Inzerce": {
    "Id": "ID of advertisement",
    "InvalidInzeratConfirm": "Do you want to invalid the advertisement?",
    "ValidInzeratConfirm": "Do you want to cancel the invalidation of the advertisement?",
    "CurrencyRequiredWhenPrice": "Field is required along with price",
    "Price": "Price",
    "currency": "Currency",
    "popis": "Description",
    "rychlyKontakt": "Quick contact",
    "datIns": "Date of creation",
    "modifiedOn": "Date of change",
    "valid": "Valid",
    "invalDuv": "Reason of invalidation",
    "cena": "Price",
    "Description": "Description",
    "AdText": "Description",
    "Dispecer": "Dispatcher",
    "History": "History",
    "OfferDetail": "Offer",
    "ArchiveConfirm": "Do you want to archive the advertisement?",
    "zadavatel": "Author"
  },
  "ItemAge": {
    "Title": "Old of the offer",
    "OldMax": "Old max",
    "FromLastBrowse": "Newer from last browse",
    "ByDate": "By date",
    "Unknown": "Unknown",
    "search": "Search"
  },
  "javax": {
    "validation": {
      "constraints": {
        "NotEmpty": {
          "message": "Field is required"
        },
        "Email": {
          "message": "Incorrect format of the email address"
        }
      },
      "user": {
        "adminWithoutProvozovna": "The administrator is not allowed to have assigned business premises",
        "userWithProvozovna": "The user must have assigned business premises"
      },
      "dispatcher": {
        "licenseLimit": "The number of dispatcher reached the limit"
      },
      "firma": {
        "requiredIdFields": "Combination of state + ID/VAT/ID VAT is required"
      },
      "phone": {
        "notValid": "Invalid format of the telephone number"
      },
      "offer": {
        "rychlyKontaktNotInProvozovna": "Telephone number is not in the list of the business premises"
      },
      "lock": {
        "couldNotLock": "Lock up of the record failed"
      },
      "waypoint": {
        "noLicense": "Road scheme does not include chosen address"
      }
    }
  },
  "Job": {
    "Stav": "Status",
    "Action": "Action"
  },
  "Licence": {
    "Id": "ID",
    "Title": "License",
    "GenerateUsers": "Generate users",
    "NumberGreaterThan": "Number must be greater than {{value}}"
  },
  "Links": {
    "ZivnostenskyCr": "Trade register of CZ",
    "ZivnostenskySk": "Trade register of SK",
    "ObchodniCr": "Business register of CZ",
    "Ares": "Administrative register of economic entities",
    "ObchodniSk": "Business register of SK"
  },
  "Map": {
    "ClearWayPoints": "Clear map",
    "SearchPlace": "Select destination or click on the map",
    "SearchNearPlace": "Select destination *",
    "noLicense": "No license",
    "noHighway": "Road not found",
    "noHighwaySelected": "No part was selected",
    "navigation": "Description of the route"
  },
  "MaterialTable": {
    "columns": {
      "showColumnsTitle": "Adjust the columns",
      "menuTitle": "Add or remove columns"
    },
    "body": {
      "emptyDataSourceMessage": "Data are not available",
      "addTooltip": "Add a new record quickly",
      "deleteTooltip": "Delete record",
      "removeTooltip": "Remove record",
      "updateTooltip": "Update record",
      "noUpdateTooltip": "Record can not be updated",
      "editTooltip": "Edit record",
      "dataChanged": "Data has been changed",
      "dateTimePickerLocalization": {},
      "filterRow": {
        "filterTooltip": "filter"
      },
      "editRow": {
        "saveTooltip": "Save",
        "cancelTooltip": "Cancel",
        "mode": {
          "add": {
            "saveTooltip": "Save new",
            "cancelTooltip": "Cancel"
          },
          "update": {
            "saveTooltip": "Save changes",
            "cancelTooltip": "Cancel changes"
          },
          "delete": {
            "saveTooltip": "Confirm deletion",
            "cancelTooltip": "Cancel deletion"
          }
        },
        "deleteText": "Do you want to delete this record?",
        "addTooltip": "Switch to the extended input",
        "editTooltip": "Switch to the extended editation",
        "readTooltip": "Switch to preview"
      }
    },
    "grouping": {
      "placeholder": "group together"
    },
    "pagination": {
      "labelDisplayedRows": "{from}-{to} from {count} ({overall} overall)",
      "labelRowsSelect": "Rows",
      "labelRowsCount": "Number of rows:",
      "labelRowsPerPage": "Number per page",
      "firstAriaLabel": "First",
      "firstTooltip": "First",
      "previousAriaLabel": "Previous",
      "previousTooltip": "Previous",
      "nextAriaLabel": "Next",
      "nextTooltip": "Next",
      "lastAriaLabel": "Last",
      "lastTooltip": "Last"
    },
    "toolbar": {
      "searchTooltip": "Search",
      "searchPlaceholder": "Search"
    },
    "header": {
      "actions": ""
    },
    "resetColumnOrder": "Reset columns",
    "resetColumnOrderQuestion": "Do you want to default settings of the columns?",
    "openMultipleAction": "Open mass action",
    "closeMultipleAction": "Close mass action",
    "export": "Export",
    "exportAll": "Export all",
    "exportFilter": "Export filter",
    "exportPdf": "Export to PDF",
    "exportXls": "Export to Excelu (XLS)",
    "exportCsv": "Export to CSV",
    "exportXml": "Export to XML",
    "exportPrint": "Print",
    "exportSelect": "Select the columns and their order"
  },
  "MuiAutocomplete": {
    "NoOption": "No results",
    "GPSCurrentPosition": "Load the current GPS position"
  },
  "MuiInput": {
    "MinTip": "Bottom limit filter",
    "MaxTip": "Top limit filter"
  },
  "NevhodnaSlova": {
    "VsichniZakaznici": "All companies",
    "ZadniZakaznici": "No companies",
    "AllWords": "All words",
    "HlidaneSlovoFirmy": "Companies using this word"
  },
  "Vozidlo": {
    "id": "ID of a vehicle",
    "ArchiveConfirm": "Do you want to archive the vehicle?"
  },
  "TransportNabidka": {
    "pocetEditaci": "Number of editations"
  },
  "Preprava": {
    "id": "ID of a cargo",
    "zadavatel": "Author",
    "regOd": "State from",
    "pscOdkud": "Post code from",
    "datOd": "Date from",
    "mena": "Currency",
    "regKam": "State to",
    "datDo": "Date to",
    "datum": "date",
    "pscKam": "Post code to",
    "cena": "Price",
    "vahaShort": "M [t]",
    "paletyShort": "P [pcs]",
    "objemShort": "V [m3]",
    "delkaShort": "L [m]",
    "sirkaShort": "W [m]",
    "vyskaShort": "H [m]",
    "lozPlochaShort": "S [m2]",
    "doplnujiciUdaje": "Specification",
    "vaha": "Weight [t]",
    "palety": "Pallet [pcs]",
    "objem": "Volume [m3]",
    "lozPlocha": "Loading area [m2]",
    "lozPlochaSmaller": "L. area [m2]",
    "delka": "Lenght [m]",
    "sirka": "Width [m]",
    "vyska": "Height [m]",
    "datIns": "Date of insert",
    "modifiedOn": "Date of change",
    "valid": "valid",
    "invalDuv": "Reason of invalidation",
    "odkud": "FROM",
    "odkudKamToolTip": "To enter multiple ICC / Post code, use a comma as a separator",
    "kam": "TO",
    "naklProstorVcelku": "Loading area altogether",
    "neverPozn": "Private note",
    "zvedaciCelo": "Tail lift",
    "druhyRidic": "Second driver",
    "zakazDokladky": "Additional load forbidden",
    "adr": "ADR",
    "hydraulickaRuka": "Hydraulic arm",
    "zpusobNakladky": "Way of loading",
    "datumZalozeni": "Date of insert",
    "datumArchivace": "Date of archiving",
    "nadrozmernyNaklad": "Over-sized cargo",
    "nakladkaZezadu": "Loading from the back",
    "nakladkaBokem": "Loading from the side",
    "nakladkaShora": "Loading from the top",
    "nakladkaZezaduShort": "from the back",
    "nakladkaBokemShort": "from the side",
    "nakladkaShoraShort": "from the top",
    "doplnky": "Additions",
    "jine": "Others",
    "souprava": "Trailer",
    "naves": "Semitrailer",
    "druhy": "Kinds",
    "verejnaPozn": "Note",
    "uzivatel": "Author",
    "dispecer": "Dispatcher",
    "History": "History",
    "InvalidPrepravaConfirm": "Do you want to invalidate the cargo?",
    "ValidPrepravaConfirm": "Do you want to cancel the invalitation of the cargo?",
    "InvalidVozidloConfirm": "Do you want to invalidate the lorry?",
    "ValidVozidloConfirm": "Do you want to cancel the invalitation of the lorry?",
    "Misto": "Place",
    "NSJAnyMustBeChecked": "Choose at least one type from STO",
    "NSJOneMustBeChecked": "Choose just one type from STO",
    "GeocodingCoordinates": "Gaining the coordinates from manually inserted address",
    "DruhyOverflow": "Maximum number {0}",
    "AirDistance": "Distance [km]",
    "AirDistanceShort": "KM",
    "AirDistanceToolTip": "The distance is calculated from an air distance",
    "CurrencyRequiredWhenPrice": "Musí být vyplněno společně s cenou",
    "okoliOdkud": "Surroundings from",
    "okoliKam": "Surroundings to",
    "radiusOdkud": "Surroundings from [km]",
    "radiusKam": "Surroundings to [km]",
    "ArchiveConfirm": "Do you want to archive cargo?",
    "rychlyKontakt": "Quick contact",
    "firma": "Company",
    "airDistance": "Distance [km]",
    "currency": "Currency",
    "nsj": "S,T,O",
    "psc": "Post code",
    "mpz": "ICC"
  },
  "Provozovna": {
    "0": "Business premises active",
    "1": "Business premises suspended",
    "Id": "ID of business premises",
    "Title": "Business premises",
    "Nazev": "Name of the business premises",
    "stopped": "Suspended",
    "PayInfo": "Invoicing",
    "souhrnne": "Summary invoicing",
    "uOsobaUser": "Accountant",
    "uTelefonUser": "Accountant telephone",
    "uEmailUser": "Accountant email",
    "uosobaUser": "Accountant",
    "utelefonUser": "Accountant telephone",
    "uemailUser": "Accountant email",
    "uosoba": "Accountant (invoicing)",
    "utelefon": "Accountant telephone (invoicing)",
    "uemail": "Accountant email (invoicing)",
    "OtherInfo": "Contacts",
    "emaily": "Emails",
    "telCislaKontaktu": "Telephone numbers",
    "FilterInfo": "Use basic / extended filter for searching of 30 relevant business premises",
    "UserIdentityLabel": "Identification of business premises",
    "CompanyContactLabel": "Company address",
    "UserContactLabel": "Address of business premises",
    "UserCorespondLabel": "Contact address",
    "KodProvozovny": "Code of business premises",
    "UserInDetail": "In detail",
    "IcoDic": "ID / EU VAT / VAT",
    "PrefStat": "State code",
    "PNazev": "P Name",
    "PUlice": "P Street",
    "PCisloPopisne": "P number of building",
    "PCisloPopisneKratke": "P n.b.",
    "PUliceCisloPopisne": "P Street + n.b",
    "PObec": "P City",
    "PCastObce": "P Part of the city",
    "PPsc": "P Post code",
    "PStat": "P State",
    "KNazev": "K Name",
    "KAdresat": "K Addressee",
    "KUlice": "K Street",
    "KCisloPopisne": "K Number of building",
    "KObec": "K City",
    "KCastObce": "K Part of the city",
    "KPsc": "K Post code",
    "KStat": "K State",
    "Kod": "Code",
    "datSmlouvyZmena": "New contract",
    "RaalJmeno1": "Raal contact 1",
    "RaalJmeno2": "Raal contact 2",
    "RaalJmeno3": "Raal contact 3",
    "KdoZmenil": "Who changed",
    "KdyZmena": "When changed",
    "PotlacInzDatum": "Ban advertising - date",
    "ZasilatReport": "Send report",
    "PotlacInz": "Ban advertising",
    "PotlacPrepravy": "Ban cargos",
    "PotlacVv": "Ban free lorries",
    "PotlacPrepravyDatum": "Ban cargos - date",
    "PotlacVvDatum": "Ban free lorries - date",
    "VytvoritProvozovnu": "New business premises",
    "ZkusebniDoba": "Trial period",
    "datzkus": "Trial period",
    "ipDll": "IP address from the online order",
    "DatovaSchranka": "Data box",
    "SmlouvaS": "Contract with",
    "EmailProfa": "Email for sending invoices and demand for payment",
    "SmsUpominky": "Telephone number for SMS demanding payment",
    "Pojisteni": "Insurance",
    "PojisteniNakladu": "Insurance of cargo",
    "ZpusobFakturace": "Way of sending invoices",
    "email1": "Sending invoices by email",
    "posta": "Sending invoices by post",
    "odlisadr": "Different address",
    "Nezobrazovat": "Do not display",
    "OmezeneInformace": "Limited information",
    "NonGdprInfo": "Non-GDPR information",
    "ZkraceneInformace": "Reduced information",
    "Fax": "Fax",
    "FrekvenceFaktur": "Rate of invoicing",
    "Stav": "Status",
    "Podstav": "Substatus",
    "Dispecer": "Dispatcher",
    "dispeceri": "Dispatchers",
    "EmailsFilter": "E-mail (searching in business premises, dispatchers, accountant)",
    "PhoneNumbersFilter": "Tel. number (searching in business premises, dispatchers, accountant)",
    "TooltipZmenaVeFirme": "Change will be visible for whole company",
    "ProcessProvozovny": "Process the other business premises of this company?",
    "not1": "Note",
    "poznamka": "Admin note",
    "Records": "Records",
    "Uzavreno": "Finished",
    "DatumUzavreni": "Date of finish",
    "ManualDataFetch": "Use basic / extended filter for searching of 30 relevant business premises",
    "PoslPripoj": "Last connection",
    "PocPripoj": "Number of connection",
    "PocetUzivatelu": "Number of users",
    "Zalozen": "Set up",
    "datzmenstav": "Change of status",
    "SpStat": "State specialization",
    "jazyky": "Languages",
    "Auta": "Own lorries",
    "vs": "Variable symbol",
    "GenerateUsers": "Generate users",
    "ToolTipGenerateUsers": "Set the number of users to be generated with licenses",
    "ContainsNumberOfBlocked": "Business premises includes telephone numbers from blocked business premises: {0}. Do you want to save record?",
    "okoli": "Surroundings",
    "radius": "Surroundings [km]",
    "airDistance": "Distance [km]",
    "notEqualAddress": "Company and business premises address do not correspond",
    "equalAddress": "Company and business premises address correspond",
    "historieUzivatelu": "History of the users",
    "Smlouvy": "Contracts",
    "LastChange": "Last change"
  },
  "PVI": {
    "RefreshRecord": "Refresh (remain {{value}}x)",
    "Refresh": "Refresh",
    "UpdateRecord": "Save changes (remain {{value}}x)",
    "Inserted": "Inserted",
    "Modified": "Change",
    "Creator": "User",
    "CreatedOn": "Date of creation",
    "ModifiedOn": "Date of change",
    "ListEmpty": "List is empty",
    "Marked": "Marked",
    "InvalDuv": "Reason of invalidation",
    "Valid": "Valid",
    "codes": {
      "P": "C",
      "V": "F",
      "I": "A",
      "All": "CFA",
      "S": "S",
      "T": "T",
      "H": "H",
      "Pr": "P",
      "U": "U",
      "F": "F"
    },
    "tooltip": {
      "P": "Cargos",
      "V": "Free lorries",
      "I": "Advertising",
      "All": "CFA",
      "S": "List of vehicles",
      "T": "Routes",
      "H": "Error announcement",
      "Pr": "Business premises",
      "U": "Users",
      "F": "Companies",
      "UpdateButton": "Saving / refreshing will update date, according to which the offers are arranged in display.",
      "UpdateDisabledButton": "Maximum number of repeated saving of the offer was used up"
    }
  },
  "RAALKratkyKody": {
    "N": "S",
    "S": "T",
    "J": "O",
    "ADR": "ADR"
  },
  "Sections": {
    "Start": "Start",
    "Task": "Input",
    "Browse": "Display",
    "Company": "Business premises",
    "Messages": "RAAL messages",
    "ArchiveTask": "Archive of own offers",
    "ArchiveBrowse": "Archive of browsed offers",
    "Pass": "Passages",
    "Fill": "Additional loads",
    "CollectionService": "Collecting service",
    "Mileage": "Road scheme",
    "Assign": "Input",
    "View": "Display",
    "Master": "Maintenance",
    "Administration": "Administration",
    "Logs": "Logs",
    "Help": "Help",
    "Zpravy": "Messages",
    "Informations": "Information",
    "Learning": "Courses",
    "Support": "User support",
    "Stiznosti": {
      "Title": "Complaints",
      "Statistiky": "Statistics"
    },
    "Admin": {
      "Offers": "Offers",
      "History": "History",
      "Archiv": "Archive"
    },
    "History": "History",
    "User": {
      "Kilometrovnik": "Road scheme"
    },
    "Search": "Search",
    "Soubory": "Files"
  },
  "ShowHidePhones": {
    "true": "Telephone numbers",
    "false": "Hide"
  },
  "ShowHideMap": {
    "true": "Set coordinates",
    "false": "Hide"
  },
  "Stat": {
    "Id": "ID",
    "nazevCz": "Name",
    "countryCode": "Code",
    "Flag": "Flag",
    "TooltipKodZeme": "Fill in the country code for OpenStreetMap",
    "predvolba": "Dialling code"
  },
  "State": {
    "true": "active",
    "false": "not active"
  },
  "SystemParameter": {
    "Value": "Value"
  },
  "Texty": {
    "Kod": "Code",
    "Hodnota": "Value ({0})",
    "TypTextu": "Type"
  },
  "Theme": {
    "ThemeChange": "Light / Dark theme",
    "Title": "Theme",
    "Dark": "Dark",
    "Light": "Light"
  },
  "ThunderNews": {
    "Notifications": "Notifcation",
    "MessagesType": "Type of message",
    "MessagesInstantLabel": "Flash message",
    "MessagesInstantUsers": "Notification: If you do not choose specific user / company / business premises, flash message will be send to ALL users!",
    "MessagesValidTo": "Valid to",
    "NoMessages": "No messages",
    "AllMessages": "All messages",
    "Predmet": "Subject",
    "TypZpravy": "Type of message",
    "Notification": "There is a new message",
    "Ok": "Hide",
    "Open": "Open"
  },
  "TimeMeasures": {
    "minutes": {
      "plural": "minutes",
      "singular": "minute",
      "genitivPlural": "minutes"
    },
    "hours": {
      "plural": "hours",
      "singular": "hour",
      "genitivPlural": "hours"
    }
  },
  "User": {
    "Id": "User ID",
    "Title": "User",
    "provozovna": "Business premises",
    "login": "Login",
    "jmeno": "User name",
    "Password": "Password",
    "SignIn": "Sign in",
    "AddNew": "Add new account",
    "ChangePassword": "Change password",
    "Switch": "Switch",
    "Remove": "Remove",
    "roles": "Role",
    "IPAdresaPosledni": "Last IP address",
    "View": "Author",
    "ViewInfo": "Information about the author",
    "ToolTipGenerateUsers": "Generating of users and licenses",
    "KdoZmenil": "Who changed",
    "NoUserRoleError": "This is one license, please stop the entire premise.",
    "lastAction": "Last action"
  },
  "LoginPage": {
    "Info": "1. DATABASE OF LOADS AND AVAILABLE CARS since 1992",
    "NewPassword": "New password",
    "ConfirmPassword": "Confirm password",
    "Captcha": "Code from image",
    "GenerateNewCaptcha": "Generate new image",
    "RememberMe": "Remember me",
    "TroubleLoggingIn": "Having trouble logging in?",
    "RegularCustomersTitle": "Regular customers",
    "RegularCustomersInfo": "The login information was sent to you by email from the address <a href=\"mailto:noreply@raaltrans.com\">noreply@raaltrans.com</a>. In case of problems, contact us <span>(+420 495 217 481 – 2)</span> and we will be happy to help you. Alternatively, you can also contact us at <a href=\"mailto:support@raal.cz\">support@raal.cz</a>.",
    "NewCustomersTitle": "New customers",
    "NewCustomersInfo": "You can find an order form on our website <a href=\"https://www.raal.cz/\" target=\"_blank\" rel=\"noopener noreferrer\">www.raal.cz</a>. We will contact you after processing the order."
  },
  "InputErrors": {
    "Password": {
      "ConfirmationNotMatch": "Passwords do not match.",
      "TooShort": "Password is too short, the minimum length is 6 characters."
    }
  },
  "UserModified": {
    "Title": "Users data changed",
    "Body": "To complete the inicialization of changes in your account it is necessary to log in again. Log out now?"
  },
  "Validations": {
    "RychlyKontaktLengthConstraint": "Number of characteristics has to be from 9 to 15"
  },
  "Websocket": {
    "Connected": "Connected",
    "Disconnected": "Disconnected",
    "Connecting": "Connecting"
  },
  "YesNo": {
    "false": "No",
    "true": "Yes"
  },
  "Kilometrovnik": {
    "Psc": "Post code",
    "Nazev": "Name",
    "Cc2": "Country code",
    "Typ": "Type",
    "Lat": "Lattitude",
    "Lon": "Longitude",
    "DisplayRoute": "Route and costs",
    "DisplayRouteOnly": "Route only",
    "Vozidlo": "Vehicle",
    "Note": "Do polí \"Přejezd odkud\" a \"Přejezd kam\" zadejte místa, která co nejvíce odpovídají Vaší trase. To může být např. místo, kde stojí nevytížené auto a místo, kam s ním chcete dojet, např. sídlo firmy. V polích \"Maximální přejezd odkud/kam/celkem\" lze nepovinně zadat, z jak velkého okolí od zadaných míst se mají nabídky zobrazovat. Necháte-li příslušné okolí nevyplněné, vyberou se nabídky bez ohledu na vzdálenost od zvoleného místa."
  },
  "Contacts": {
    "SendEmail": "Send email",
    "SipEmail": "Contact via SIP protocol (Teams, aj.)",
    "Call": "Call",
    "SMS": "Send SMS",
    "WhatsApp": "WhatsApp",
    "Viber": "Viber"
  },
  "Klasifikace": {
    "klasifikace": "Clasification",
    "klasifikaceShort": "[K]",
    "nezobrazovat": "Do not show offers",
    "poznamka": "User notice"
  },
  "ProfilVozidla": {
    "title": "Vehicles",
    "nazev": "Name",
    "casovyNakladH": "Time costs [price/h]",
    "casovyNakladHTooltip": "(for ex. driver´s salary)",
    "fixniNaklad": "Fixed costs [price/km]",
    "fixniNakladTooltip": "(amortization)",
    "spotreba": "Fuel consumption [l / 100 km]",
    "cenaPaliva": "Fuel price [price/l]",
    "tollPrice": "Toll rate",
    "osrmProfile": "Navigační profil",
    "displayPreprava": "Display in cargos",
    "displayVozidlo": "Display in free lorries",
    "defaultPreprava": "Default in cargos",
    "defaultVozidlo": "Default in free lorries",
    "properties": "Vehicle attributes",
    "maxWeight": "Total weight of vehicle [t]",
    "maxHeight": "Total height of vehicle [m]",
    "maxWidth": "Total width of vehicle [m]",
    "maxLength": "Total lenght of vehicle [m]",
    "hazmat": "transport of dangerous materials",
    "hazmatWater": "transport of materials causing the pollution of water",
    "switzerland": "avoid Switzerland if there is no loading/uploading",
    "delka": "Lenght of cargo [m]",
    "vaha": "Weight of cargo [t]"
  },
  "CenaMyta": {
    "title": "Toll rate",
    "kodZeme": "State",
    "sazba": "Rate [price/km]",
    "typSilnice": "Route type",
    "CurrencyRequiredWhenPrice": "Field is required along with rate"
  },
  "Trasa": {
    "title": "Routes",
    "Calculate": "Recalculate",
    "CalculateAndSave": "Recalculate and save",
    "nazev": "Name",
    "state": "Calculation proceeding",
    "profilVozidla": "Profile of vehicle",
    "currency": "Currency",
    "distance": "Distance",
    "duration": "Duration",
    "cost": "Costs",
    "mpz": "ICC",
    "distancePaid": "Paied distance [km]",
    "distanceUnpaid": "Unpaied distance [km]",
    "currencyTooltip": "Indicative calculation according the Czech National Bank exchange rate",
    "modifiedOn": "Date of change"
  },
  "KilometrovnikHlaseni": {
    "osrmId": "OSRM Id",
    "report": "Report",
    "status": "Status",
    "uzivatel": "User",
    "osrmEdit": "Report to OSRM",
    "process": "Close",
    "reportSent": "Announcement was sent",
    "title": "Reported errors",
    "datIns": "Date of creation",
    "datUzavreni": "Date of closure"
  },
  "Dokladky": {
    "kilometrovnik": "Road scheme additional load",
    "nakladka": "Loading",
    "vykladka": "Uploading",
    "odkud": "Cargo - from",
    "kam": "Cargo - to",
    "vyhodnost": "Effectiveness",
    "dokladky": "Additional loads",
    "zajizdka": "Detour [km]",
    "datumDokladkyOd": "Date of additional load from",
    "datumDokladkyDo": "Date of the additional load to",
    "zadaniDokladky": "Additional load",
    "calculate": "Search additional loads",
    "preprava": "Cargo",
    "total": "Total",
    "original": "Original",
    "dokladka": "With additional load",
    "Preprava": "Initial cargo",
    "datum": "Date"
  },
  "SbernaSluzba": {
    "sbernaSluzba": "Collective service",
    "datumOd": "Cargos from",
    "datumDo": "Cargos to",
    "calculate": "Calculate",
    "trasa": "Routes",
    "vozidlo": "Vehicle",
    "datum": "Date",
    "search": "Enter collective service",
    "prepravaOrder": "Order",
    "map": "Show map",
    "noTransportsFound": "No suitable cargos found",
    "profilVozidlaAtributy": "Attributes of vehicle must be filled in: currency, consumption, fixed costs",
    "parametryVypoctu": "Specifications of calculation",
    "maxPrejezd": "Maximum passage from the place of loading [km]",
    "maxZajizdka": "Maximum detour on the route [km]",
    "nalezeniPreprav": "Fulfillment of collective service",
    "neobsahujePrepravy": "Load the cargos automatically?",
    "doplnitPrepravy": "Automatically load cargos",
    "pridatPrepravu": "Add cargo manually",
    "pridaniPrepravy": "Add cargo automatically",
    "calculateToll": "Calculate costs",
    "maxDelka": "Maximum lenght of partial cargos [m]",
    "maxVaha": "Maximum weight of partial cargos [t]",
    "PrepravaRemoveConfirm": "Do you want to remove main offer? All offers will be deleted.",
    "noveZadani": "New input",
    "noveZadaniBody": "The input includes cargos, do you want to delete them and start again?",
    "processing": "Action can not be processed. The calculation is proceeding."
  },
  "ServerErrors": {
    "404": "Information was not found on server",
    "500": "Server internal error",
    "FailedToFetch": "Loading of data failed, check the internet connection.",
    "UnableToGeocode": "'Unable to geocode coordinates",
    "NetworkError": "Network error during the processing of the request, check the internet connection."
  },
  "SeznamMaps": {
    "Title": "Map Seznam.cz"
  },
  "SearchEnum": {
    "entityType": "Type of record",
    "Preprava": {
      "PREPRAVA": "N",
      "PREPRAVA_HISTORIE": "N (H)",
      "PREPRAVA_ARCHIV": "A",
      "PREPRAVA_HISTORIE_ARCHIV": "A (H)"
    },
    "VolneVozy": {
      "VOZIDLO": "N",
      "VOZIDLO_HISTORIE": "N (H)",
      "VOZIDLO_ARCHIV": "A",
      "VOZIDLO_HISTORIE_ARCHIV": "A (H)"
    },
    "Inzerat": {
      "INZERAT": "N",
      "INZERAT_HISTORIE": "N (H)",
      "INZERAT_ARCHIV": "A",
      "INZERAT_HISTORIE_ARCHIV": "A (H)"
    }
  },
  "TrikratADost": {
    "Title": "Statistika \"3x and enough\" ke dni {0}",
    "celkemPripadu": "Cases {0} in total",
    "celkemCastky": "Total amount: {0}",
    "NadpripadyTotal": "Overcases: {0} opened a {1} closed, from which {2} closed terminations",
    "Stav": "Status",
    "Castka": "Sum of money",
    "CurrencyCode": "Currency",
    "PocetPripad": "Number of cases"
  },
  "DOMException": {
    "AbortError": "Loading of data was interrupted (or the server failed to respond in required time)"
  },
  "Diag": {
    "host": "Guest",
    "serverPort": "Port",
    "healthy": "Status",
    "instances": "Instance",
    "dbReplicas": "Database replicas",
    "couldNotLoad": "Unable to load data",
    "replicationNotActive": "Database is not replicated",
    "resources": "Recourses",
    "requiredDbConnectionsRead": "Required DB connections for reading",
    "requiredDbConnectionsWrite": "Required DB connections for record",
    "availableDbConnectionsRead": "Available DB connections for reading (1 db)",
    "availableDbConnectionsWrite": "Available DB connections for record",
    "availableDbConnections": "Available DB connections",
    "dbConnectionsNotEnough": "The number of database connections is not enough to cover the server requirements",
    "jobs": "Jobs",
    "rateLimit": "Rate limit info"
  },
  "ArchiveRecord": {
    "typ": "Type",
    "poznamka": "Note",
    "owner": "Archived"
  },
  "Archive": {
    "Archive": "Archive",
    "Archived": "Archived"
  },
  "Poznamka": {
    "poznamka": "Note",
    "uzivatel": "Last author",
    "datIns": "Date of creation"
  },
  "CommonGrid": {
    "History": "History"
  },
  "DluhNepohOsoba": {
    "Prijmeni": "Surname",
    "Jmeno": "Name",
    "Rodcis": "Personal identification number",
    "Adresa": "Address",
    "Ica": "ID number",
    "Poznamka": "Note",
    "DatCas": "Date",
    "Uzivatel": "Changed by",
    "Provozovny": "Business premises"
  },
  "EmailForm": {
    "Sender": "Sender",
    "Subject": "Subject",
    "Receiver": "Send to email address",
    "Body": "Email content"
  },
  "EmailStiznostiForm": {
    "smlouva": "Date of contract",
    "pozastaveno": "Suspended from"
  },
  "WithUserBase": {
    "Id": "Number",
    "DatCas": "Date of the last change",
    "Uzivatel": "Last changed by",
    "Zmena": "Last change",
    "Generovat": "Generate and send",
    "ChooseLanguage": "Choose a language"
  },
  "DluhPripad": {
    "Dluznik": "Debtor",
    "Veritel": "Creditor",
    "ZodpOsoba": "Responsible person",
    "IcoDluznika": "ID number of debtor",
    "DicDluznika": "VAT of debtor",
    "RegNazevDluznika": "Name of debtor",
    "RegUliceDluz": "Street of debtor",
    "RegSidloDluz": "Address of debtor",
    "RegPscDluz": "Post code of debtor",
    "RegStatDluz": "State of debtor",
    "PrefStatDluz": "Code of debtor",
    "PrefMestoDluz": "Code of city",
    "FaxDluz": "Fax of debtor",
    "TelDluz": "Telephone of debtor",
    "MobilDluz": "Mobile pnohe of debtor",
    "HttpDluz": "WWW of debtor",
    "EmailDluz": "Email of debtor",
    "IcqDluz": "Icq of debtor",
    "SkypeIdDluz": "Skype of debtor",
    "ProvozovnaDluznika": "Business premises of debtor",
    "ProvozovnaDluznikaStav": "State of business premises of debtor",
    "zodpMailDluz": "Responsible person of debtor",
    "IcoVer": "ID number of creditor",
    "DicVer": "VAT of creditor",
    "RegNazevVer": "Name of creditor",
    "RegUliceVer": "Street of creditor",
    "RegSidloVer": "Address of creditor",
    "RegPscVer": "Post code of creditor",
    "RegStatVer": "State of creditor",
    "PrefStatVer": "Code of creditor",
    "ProvozovnaVer": "Business premises of creditor",
    "ProvozovnaVerStav": "Status of business premises of creditor",
    "zodpMailVer": "Responsible person of creditor",
    "PrefMestoVer": "Code of city of creditor",
    "FaxVer": "Fax of creditor",
    "TelVer": "Telephone of creditor",
    "MobilVer": "Mobile phone of creditor",
    "HttpVer": "WWW of creditor",
    "EmailVer": "Email of creditor",
    "IcqVer": "Icq of creditor",
    "SkypeIdVer": "Skype of creditor",
    "KdoZaloz": "Initiated by",
    "DatCas": "Date",
    "Stav": "Status",
    "ZodpTel": "Telephone of responsible person",
    "ZodpEmail": "Email of responsible person",
    "Kontakty": "Contacts",
    "ProvozovnyFirmy": "Business premises of superordinate company",
    "AktKeDni": "Update to date",
    "AktKeDniButton": "Now",
    "GenerovatDopisVer": "Generate and send letter to creditor",
    "GenerovatDopisDluznik": "Generate and send letter to debtor",
    "StavProvozovnyDluznika": "Status of business premises of debtor",
    "StavProvozovnyVer": "Status of business premises of creditor",
    "StavAction": {
      "otevrit": "Open",
      "uzavrit": "Close",
      "vyresitBezUhrady": "Solved without payment",
      "promlcet": "statute of limitations",
      "zamitnout": "Deny",
      "Confirmation": "Status was successfully changed"
    },
    "PripadExist": "Warning! There are active cases with identical debtor and creditor in the system: {pripadyIds}! Would you like to continue?",
    "FaktExist": "Warning! There is the invoice number in the system in cases: {pripadyIds}! Would you like to continue?",
    "PripadExistTitle": "Confirmation",
    "FaktExistTitle": "Confirmation",
    "Active": "Active"
  },
  "DluhNadpripad": {
    "NadpripadId": "Overcase",
    "Stav": "Status",
    "KdyPozastavit": "When to suspend",
    "Vypoved": "Termination",
    "Bod39": "Point 3.9",
    "Upozorneni": "Notice",
    "Confirmation": "Confirmation",
    "GenerovatDopisDluznik": "Generate and send a letter to deptor",
    "StavAction": {
      "otevrit": "Open",
      "uzavritSUhradou": "Close (with payment)",
      "uzavritBezUhrady": "Close (without payment)",
      "uzavritVypoved": "Close (termination)",
      "uzavrit39": "Close (point 3.9)",
      "New": "Would you like to set an overcase?",
      "CreateConfirmation": "The overcase was successfully created"
    },
    "Buttons": {
      "New": "Set overcase",
      "Navigate": "Show overcase",
      "NavigateToPripad": "Back to the case"
    }
  },
  "DluhFakt": {
    "Title": "Invoices",
    "Cislo": "Invoice number",
    "Castka": "Amount",
    "DatSplat": "Due date",
    "KdyUhradil": "Payed when",
    "KdoUhradil": "Who payed",
    "KdyBezUhrady": "When without payment",
    "KdoBezUhrady": "Who without payment",
    "Stav": "Status",
    "UdajeFaktury": "Invoice´s data",
    "OhlasUhr": "Payment notification by creditor",
    "DuvodZamitnuti": "Reason for refusal",
    "Poznamka": "Note",
    "Poznamky": "Notes",
    "StavAction": {
      "uhradit": "Pay",
      "vyresitBezUhrady": "Solve without payment",
      "promlcet": "Statute of limitations",
      "zamitnout": "Deny",
      "otevrit": "Open"
    }
  },
  "DluhSoub": {
    "Title": "Files",
    "Soubor": "Files",
    "NovySoubor": "New file",
    "Popis": "Description",
    "Stazeni": "Loading of file"
  },
  "DluhStateActionConfirmDialog": {
    "Title": "Confirmation",
    "Body": "Do you want to carry out this action {{action}}?"
  },
  "ReportText": {
    "TextKey": "Key",
    "Translation": "Translation"
  },
  "Report": {
    "Language": "Language"
  },
  "popis": "Description",
  "NetworkError": "Network error",
  "UnexpectedError": "Unexpected error occured during the processing of the request",
  "Error401": "Signed out",
  "Error401FingerPrint": "You were signed out. You signed in in different browser.",
  "SignedOut": "Signed out",
  "BadCredentials": "Wrong login",
  "RecordCannotBeSaved": "Record can not be saved",
  "ModificationConcurrency": "Entity was modified by other user",
  "LoginFailed": "Login failed",
  "LoginFailedGeneratedPwd": "Login failed. Please check that you have entered the correct password using the eye button next to the password.",
  "PasswordChangeRequired": "Password change required",
  "TryConnectAgain": "Try to connect again",
  "ConnectionError": "Connection error. Servers are not available or there is no internet connection.",
  "Unique": "Record is duplicit",
  "ipDll": "Last IP",
  "DataFetchError": "Data fetch error",
  "WebsocketConnecting": "Establishing connection",
  "Attempt": "Attempt",
  "ArchiveRecordShort": "Archive",
  "RestoreRecordShort": "Restore",
  "ArchiveSuccess": "Archivation successful",
  "AppActivityTypeName": "Type",
  "AppActivitySubTypeName": "Subtype",
  "ActivityViewType": "View type",
  "ArchiveView": "Archivation of displayed offer",
  "Actions": "Actions",
  "Errors": {
    "CouldNotFetchCode": "Error during the loading of free code of business premises.",
    "CouldNotFetchCount": "Error during the loading of the number of records.",
    "CouldNotCancelEdit": "Editation can not be cancelled",
    "CouldNotEdit": "Record can not be editted",
    "CouldNotDelete": "Record can not be deleted",
    "CouldNotDeleteIsLocked": "Record can not be deleted, record is locked",
    "CouldNotArchiveIsLocked": "Record can not be archived, record is locked",
    "LockLost": "Editation was cancelled, because the locking of record was cancelled",
    "SystemOverload": "Page can not be found, because the system is overload, try to display it later."
  },
  "Unable to geocode": "Unable to geocode",
  "Consent": {
    "BasicInfo": "Basic information",
    "Agreements": "Agreements",
    "MinimalRequirements": "Approval of legal documentation",
    "VPAgreement": "I agree to the general conditions",
    "LPAgreement": "I agree to the license terms",
    "GDPRAgreement": "I agree to the privacy policy",
    "PDIAgreement": "I agree to the contract for the provision of traffic information",
    "Download": "Download",
    "Open": "Open"
  },
  "PravniDokumentace": {
    "TypSmlouvy": "Contract Type",
    "Soubor": "Contract",
    "DatumPodepsaniRall": "RAALTRANS signing Date",
    "DatumPodepsaniPrijemce": "Recipient confirmation Date",
    "DatumVytvoreni": "Creation Date",
    "Sign": "Sign contracts",
    "SignConfirm": "Do you really want to sign all contracts?",
    "AlreadySigned": "Contracts have already been signed",
    "SignFailed": "Signing of contracts failed",
    "SignSuccess": "Contracts have been signed",
    "Delete": "Delete contracts",
    "DeleteConfirm": "Are you sure you want to delete all contracts?",
    "AlreadyDeleted": "Contracts have already been deleted",
    "DeleteFailed": "Failed to delete contracts",
    "DeleteSuccess": "Contracts have been deleted"
  },
  "StaticLinks": {
    "Thumbnail": {
      "MasterTutorial": "/videos/thumbnail/master_tutorial_en.jpg"
    }
  },
  "Cms": {
    "Oznameni": {
      "TitleAfterLogin": "News since last login",
      "TitleStomp": "Important notice",
      "ReloadPage": "REFRESH PAGE",
      "ReloadInfo": "The application has just been updated to a new version - the page needs to be refreshed for proper operation.",
      "ReloadDisclaimer": "Otherwise, some of the functions may not work correctly."
    }
  },
  "Oznaceni": {
    "DeleteViewRecords": "Clear view history",
    "ViewRecordsClearConfirm": "Are you sure you want to clear your view history?"
  },
  "NabidkaMultiCRUD": {
    "RefreshTooltip": "Refresh records",
    "DeleteTooltip": "Delete records",
    "RefreshConfirm": "Are you sure you want to refresh all selected records?",
    "DeleteConfirm": "Are you sure you want to delete all selected records?",
    "RefreshSuccess": "Offers have been refreshed",
    "DeleteSuccess": "Offers have been deleted",
    "UnableToRefresh": "Some offers could not be refreshed",
    "UnableToDelete": "Some offers could not be deleted",
    "AlreadyDeletedRecords": "Already non-existent: {0}x",
    "LockedRecords": "Locked: {0}x",
    "MaxEditsReachedRecords": "Number of refreshes exhausted: {0}x",
    "ExpiredOfferRecords": "Invalid offers: {0}x",
    "InvalidOffersDateChangeInfo": "Selected offers contain a date that is already in the past. To enable renewal, a new date must be set:",
    "ValidOffersDateChangeInfo": "Note: The selection includes valid offers - for these, only the end date will be changed.",
    "DateFrom": "Date From",
    "DateTo": "Date To"
  },
  "VlastniMisto": {
    "titulek": "Adding a new place",
    "Pridat": "Add a new place",
    "mpz": "ICC",
    "psc": "Post Code",
    "mesto": "City",
    "notFound": "The location has not been found. You can use it, but the distance kilometres will not be calculated."
  },
  "Mail": {
    "KodZadavateleNabidky": "BIDDER CODE",
    "PrepravaSubject": "Reaction to transport offer in RAALTRANS ({0})",
    "VozidloSubject": "Reaction to the offer of a free lorry in RAALTRANS ({0})",
    "InzeratSubject": "Reaction to the advertisement offer in RAALTRANS ({0})",
    "PrepravaBodyBegin": "We are responding to your transport offer published in the RAALTRANS database (our code in RAALTRANS is {0}):",
    "VozidloBodyBegin": "We are responding to your free lorry offer published in the RAALTRANS database (our code in RAALTRANS is {0}):",
    "InzeratBodyBegin": "We respond to your ad published in the RAALTRANS database (our code in RAALTRANS is {0}):"
  },
  "DataTemplate": {
    "Save": "Save template",
    "Name": "Template Name",
    "Add": "Add template",
    "Select": "Select a template to create a menu",
    "SavedTemplates": "Saved Templates",
    "NoData": "No template found",
    "DeleteConfirmationText": "Are you sure you want to delete the template?",
    "RemoveTemplate": "Delete",
    "Saved": "The template has been saved",
    "SaveFailed": "The template could not be saved, the name is already assigned to another template, or the name is too long",
    "Edited": "The template has been edited",
    "EditFailed": "Failed to edit template",
    "Removed": "The template has been removed",
    "RemoveFailed": "Failed to remove template"
  },
  "Here": {
    "Dial": {
      "TitulekTabulky": "Vehicles",
      "Nazev": "Name",
      "Mena": "Currency",
      "Vyska": "Height [m]",
      "Sirka": "Width [m]",
      "Delka": "Length [m]",
      "CelkovaVaha": "Total weight [t]"
    },
    "TrasaDetail": {
      "CelkovaCenaZaSekci": "Total price for the section",
      "PoplatkyV": "Fees in",
      "Routing": "ROUTING",
      "Sekce": "Section",
      "OdhadovanaDobaJizdy": "Estimated travel time",
      "Poplatky": "TOLL",
      "PoplatkyCelkem": "Total fees",
      "DetailPoplatku": "Click here to expand/collapse toll detail",
      "Table": {
        "IndexMyta": "Toll index",
        "IndexPoplatku": "Fee index",
        "LokalniCena": "Local price",
        "KovertovanaCena": "Converted price",
        "Zeme": "Country",
        "MytnySystem": "Toll system",
        "PlatebniMetody": "Payment methods",
        "PlatebniMetoda": {
          "cash": "Cash",
          "bankCard": "Bank card",
          "creditCard": "Credit card",
          "transponder": "Transponder",
          "travelCard": "Travel card",
          "passSubscription": "Fare subscription",
          "videoToll": "Video toll"
        },
        "Predplatne": {
          "days": "Daily",
          "months": "Monthly",
          "annual": "Annual"
        }
      },
      "HereError": "The vehicle profile likely contains prohibited combinations.",
      "HereErrorNoRoutes": "No route was found according to the location and profile of the vehicle. This could be due to, for example, a restricted country and a route starting or ending in that country.",
      "Upozorneni": "Warning",
      "NotTruckTransportNotification": "Please note: on the route, some of the transport is different (ferry/train) than on the actual axis. Charges may not be complete.",
      "NotTollsTransportNotification": "Please note there are no fees listed in this section. It does not mean that there are none. It is possible that the system was unable to detect them.",
      "ZobrazitMapu": "Click here to expand/collapse the map",
      "KliknutimVybertePrujedniBod": "Click on the map to select a waypoint.",
      "VyberZMapy": "Selection from the map"
    },
    "Kilometrovnik": {
      "Button": {
        "Trasa": "ROUTE",
        "Naklady": "TOLL",
        "TrasaANaklady": "ROUTE AND TOLL"
      },
      "ProfilVozidla": "Vehicle profile",
      "ViaInput": "Passing point",
      "AddViaToList": "Add to list"
    },
    "Nazev": "Vehicle profile name",
    "Currency": {
      "Title": "Costs to return in currency",
      "TitleToolTip": "The costs of the calculated route will be returned in this currency",
      "Shortcut": {
        "EUR": "Euro",
        "GBP": "British Pound",
        "CHF": "Swiss Franc",
        "NOK": "Norwegian Krone",
        "SEK": "Swedish Krona",
        "DKK": "Danish Krone",
        "ISK": "Icelandic Krona",
        "CZK": "Czech Koruna",
        "PLN": "Polish Zloty",
        "HUF": "Hungarian Forint",
        "RON": "Romanian Leu",
        "BGN": "Bulgarian Lev",
        "HRK": "Croatian Kuna",
        "RSD": "Serbian Dinar",
        "TRY": "Turkish Lira",
        "BAM": "Bosnia and Herzegovina Convertible Mark",
        "MKD": "Macedonian Denar",
        "BYN": "Belarusian Ruble",
        "MDL": "Moldovan Leu",
        "UAH": "Ukrainian Hryvnia",
        "RUB": "Russian Ruble",
        "GEL": "Georgian Lari",
        "AZN": "Azerbaijani Manat",
        "AMD": "Armenian Dram",
        "ALL": "Albanian Lek"
      }
    },
    "Vehicle": {
      "Section": "Basic information about the vehicle",
      "PayloadCapacity": "Permissible load capacity including trailers [t]",
      "Type": "Type of freight vehicle",
      "TypeEnum": {
        "straightTruck": "Straight truck",
        "tractor": "Tractor"
      },
      "TunnelCategory": "Tunnel category",
      "TunnelCategoryToolTip": "Tunnel category for restricting the transport of specific goods",
      "TrailerAxleCount": "Total number of axles (all trailers)",
      "TrailerAxleCountToolTip": "Total number of axles across all trailers attached to the vehicle",
      "AxleCount": "Total number of axles in the vehicle",
      "TrailerCount": "Number of trailers",
      "WeightPerAxleGroup": "Weight per axle group [t]",
      "WeightPerAxle": "Weight per axle [t]",
      "GrossWeight": "Total weight [t]",
      "Length": "Length [m]",
      "Width": "Width [m]",
      "Height": "Height [m]"
    },
    "TollEmission": {
      "Section": "Parameters for toll calculation based on emissions",
      "TollEmissionType": "Emission type",
      "TollEmissionTypeCO2EmissionClass": "CO2 emission class",
      "Type": {
        "euro1": "EURO1",
        "euro2": "EURO2",
        "euro3": "EURO3",
        "euro4": "EURO4",
        "euro5": "EURO5",
        "euro6": "EURO6",
        "euroEev": "EUROEEV"
      }
    },
    "HazardousGoods": {
      "Section": "List of hazardous goods transported in the vehicle",
      "HazardousGood": {
        "explosive": "Explosive",
        "gas": "Gas",
        "flammable": "Flammable",
        "combustible": "Combustible",
        "organic": "Organic",
        "poison": "Poison",
        "radioactive": "Radioactive",
        "corrosive": "Corrosive",
        "poisonousInhalation": "Poisonous inhalation",
        "harmfulToWater": "Harmful to water",
        "other": "Other"
      }
    },
    "AvoidFeatures": {
      "Section": "Avoid specific paths",
      "Feature": {
        "tunnel": "Tunnel",
        "ferry": "Ferry"
      }
    },
    "ExcludeCountry": {
      "Section": "Avoid the following countries",
      "Country": {
        "ALB": "Albania",
        "AND": "Andorra",
        "ARM": "Armenia",
        "AUT": "Austria",
        "BLR": "Belarus",
        "BEL": "Belgium",
        "BIH": "Bosnia and Herzegovina",
        "BGR": "Bulgaria",
        "HRV": "Croatia",
        "CYP": "Cyprus",
        "CZE": "Czech Republic",
        "DNK": "Denmark",
        "EST": "Estonia",
        "FIN": "Finland",
        "FRA": "France",
        "GEO": "Georgia",
        "DEU": "Germany",
        "GRC": "Greece",
        "HUN": "Hungary",
        "ISL": "Iceland",
        "IRL": "Ireland",
        "ITA": "Italy",
        "XKX": "Kosovo",
        "LVA": "Latvia",
        "LIE": "Liechtenstein",
        "LTU": "Lithuania",
        "LUX": "Luxembourg",
        "MLT": "Malta",
        "MDA": "Moldova",
        "MCO": "Monaco",
        "MNE": "Montenegro",
        "NLD": "Netherlands",
        "MKD": "North Macedonia",
        "NOR": "Norway",
        "POL": "Poland",
        "PRT": "Portugal",
        "ROU": "Romania",
        "RUS": "Russia",
        "SMR": "San Marino",
        "SRB": "Serbia",
        "SVK": "Slovakia",
        "SVN": "Slovenia",
        "ESP": "Spain",
        "SWE": "Sweden",
        "CHE": "Switzerland",
        "TUR": "Turkey",
        "UKR": "Ukraine",
        "GBR": "United Kingdom",
        "VAT": "Vatican"
      }
    },
    "DefaultPVSettings": {
      "Section": "Settings in transports and free vehicles",
      "Preprava": "Default in transports",
      "Vozidlo": "Default in free vehicles"
    },
    "CustomNaklady": {
      "NaHodinu": "Cost per hour",
      "NakladNaKm": "Cost per km",
      "CelkemZaHodin": "Route cost (distance * hourly rate)",
      "CelkemZaKm": "Route cost (distance * price per km)",
      "Celkem": "Total cost per route",
      "Titulek": "CUSTOM COSTS"
    }
  }
};
export default en;