import React, {useRef} from "react";
import {
    InvalidDuvodInzerat,
    InzeratFilterOznaceno,
    InzeratView,
    InzeratViewAdmin,
    InzeratViewDetail
} from "../../../../model/Inzerat";
import {Dial} from "../../../../raal_components/dial/Dial";
import {useTranslation} from "react-i18next";
import {FormInputType} from "../../../../raal_components/form/Form";
import {useEnumeratedSelect, useInzeratInvalDuvSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {FormNumberRange} from "../../../../../common/component/form/FormNumberRange";
import {formatPrice, truncateString} from '../../../../../common/utils/Util';
import {useAppContext} from "../../../../context/AppContext";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {globalStyles} from "../../../../context/ThemeModeContext";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {cenaConstraint, getExportableProps} from '../_vp/PrepravaAVozidlaShared';
import {cenaFormat, inzerceAdminExportableFields, useInzercePdfLayout} from "../zadani/InzerceCiselnik";
import {PhoneNumberDial} from "../../../../../common/component/PhoneNumberDial";
import {FormDateRange} from "../../../../../common/component/form/FormDateRange";
import {InzerceViewCiselnikFilter} from "../prohlizeni/InzerceViewCiselnik";
import {InzeratSearchEntityType,} from "../../../../model/CommonTypes";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {InzeratViewDetailFormBasicPartNew} from "../_inzerce/InzerceCiselnikViewDetailFormBasicPart";
import {RouteComponentProps} from "../../../../routes";
import {getEntityTypeUrl} from "./SearchFunctions";
import {InlineEmailClient} from "../../../../../common/component/EmailClient";

export default function InzerceCiselnikSearch(props: RouteComponentProps) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const rangeCenaSharedOptions = {numberProps: {format: cenaFormat, constraint: cenaConstraint}};
    const dateKey = "inzerat-search-browse-date";
    const yesNoSelect = useYesNoSelect({isClearable:true, autoOpenDisabled: true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.I);
    const endpoint = "admin/inzerat-search-union";
    const invalDuvSelect = useInzeratInvalDuvSelect({isClearable: true, autoOpenDisabled: true});
    const dtGrid = useRef<DataGridExposed<InzeratViewAdmin, InzeratFilterOznaceno>>();
    const {pdfLayout} = useInzercePdfLayout(user);
    const searchTypeSelect = useEnumeratedSelect(InzeratSearchEntityType, "SearchEnum.Inzerat", "string", {isClearable:true, autoOpenDisabled: true});
    return <Dial<InzeratViewAdmin, InzeratFilterOznaceno, InzeratViewDetail>
        mode={props.mode}
        lastBrowsedDateKey={dateKey}
        logActivity
        gridRef={dtGrid}
        filterClazz={InzeratFilterOznaceno}
        clazzDetail={InzeratViewDetail}
        config={{
            lockSupport: {enabled: false},
            getDetailIdUrlPart: (data) : string =>
                (`${getEntityTypeUrl(data.entityType)}${data.id}`),
            overflowHidden: true,
            exportConfig: {
                exportable: true,
                endpoint: endpoint,
                getExportDetailIdUrlPart: (data) =>
                    ({endpoint: getEntityTypeUrl(data.entityType), id: data.id}),
                exportAll: true,
                fileName: "export_vyhledavani_inzerce",
                translationPrefix: ['Inzerce', 'User', 'Preprava', 'Archive'],
                formats: ["pdf", "xls", "csv", "xml"],
                exportAllFormats: ["csv"],
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                exportableProps: getExportableProps(inzerceAdminExportableFields, true),
                excludedProps: [
                    {
                        type: ["csv", "xls"],
                        fields: ["id", "sekce"]
                    }
                ],
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod,
                                archived: data.deletedOn
                            }
                        }
                    }
                 ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            options: {
                rowStyle: (data: any) => {
                    if (!data.valid)
                        return globalStyles.rowStyleAlert;
                    if(data.invalDuv === InvalidDuvodInzerat.DUPLICITA){
                        return globalStyles.rowStyleDuplicated;
                    }
                    return undefined;
                }
            },
            clazz: InzeratViewAdmin,
            hideDefaultDummyAction: false,
            tableTitle: t("Dials.Advertising"),
            endpoint: endpoint,
            filtering: true,
            hideAddNewActions: true,
            columns: [
                {
                    title: t("Inzerce.AdText").toUpperCase(),
                    field: 'popis',
                    filterProps:() => ({type:FormInputType.Text}),
                    cellStyle: {minWidth: 200},
                    headerStyle: {minWidth: 200}
                },
                {
                    title: t("Default.Telefon").toUpperCase(),
                    field: 'rychlyKontakt',
                    render:(row:InzeratView)=><PhoneNumberDial hideIcon phoneNumber={row.rychlyKontakt} />,
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title:t('Default.Email').toUpperCase(),
                    field:"email",
                    filterProps: () => ({type: FormInputType.Text}),
                    sorting: false,
                    render:(data:InzeratView)=> <InlineEmailClient hideIcon emailAddress={data.email} rowData={data}/>,
                },
                {
                    title: t("Inzerce.Price").toUpperCase(),
                    field: 'cena',
                    render:(data:InzeratView) => data.cena && data.currency?.currencyCode ? formatPrice(data.cena, data.currency.currencyCode) : data.cena ?? '',
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormNumberRange, name:"cenaRange", customComponentOptions:rangeCenaSharedOptions}),
                    cellStyle: {textAlign:"right"}
                },
                {
                    title: t("Provozovna.Title").toUpperCase(),
                    field: 'provozovna',
                    render:(data:InzeratView) => data.provozovna && data.firma ? truncateString(`${data.provozovna} (${data.firma})`, 20) : '',
                    filterProps: () => ({type: FormInputType.Text})
                },
                {
                    title: t("Inzerce.Dispecer").toUpperCase(),
                    field: 'dispecer',
                    render:(row:InzeratView)=> `${row.dispecer?.jmeno ?? ''}`,
                    filterProps:() => ({type: FormInputType.Text, name: "dispecerText"}),
                    cellStyle: {width: 200, maxWidth: 200},
                    headerStyle: {width: 200, maxWidth: 200}
                },
                {
                    title: t("PVI.Inserted").toUpperCase(),
                    field: 'datIns',
                    render: (data:InzeratView) => data.datIns?.format("L LT")
                },
                {
                    title: t("PVI.Modified").toUpperCase(),
                    field: 'modifiedOn',
                    defaultSort: "desc",
                    render: (data: any) => data.modifiedOn?.format("L LT"),
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false},  name: "modifiedOnRange"}),
                },
                {
                    title: t("PVI.Valid").toUpperCase(),
                    field: 'valid',
                    render: (data: InzeratViewAdmin) => <CheckMark checked={data.valid}/>,
                    filterProps: () => ({type: FormInputType.Select, selectProps: yesNoSelect})
                },
                {
                    title: t("PVI.InvalDuv").toUpperCase(),
                    field: 'invalDuv',
                    render: (data: InzeratViewAdmin) =>  {return locFun("Enumerations.InvalidDuvodInzerat", InvalidDuvodInzerat, data.invalDuv)},
                    filterProps: () => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
                    cellStyle: {minWidth: 180},
                    headerStyle: {minWidth: 180}
                },
                {
                    title: t("User.Title").toUpperCase(),
                    field: 'uzivatelText',
                    render:(data:InzeratViewAdmin)=> data.uzivatel?.toString() ?? "",
                    filterProps: () => ({type: FormInputType.Text, name: "uzivatelText"}),
                    cellStyle: {minWidth: 180},
                    headerStyle: {minWidth: 180}
                },
                {
                    title: t("Archive.Archived").toUpperCase(),
                    field: 'deletedOn',
                    render:(data:InzeratViewAdmin) =>  data.deletedOn?.format("L LT") ?? "",
                    filterProps:() => ({type:FormInputType.Custom, customComponent:FormDateRange, customComponentOptions:{timeFormat: false},  name: 'deletedOnRange'}),
                },
                {
                    title: t("SearchEnum.entityType").toUpperCase(),
                    field: 'entityType',
                    filterProps: () => ({type: FormInputType.Select, selectProps: searchTypeSelect}),
                    render:(data:InzeratViewAdmin) =>  t(`SearchEnum.Inzerat.${data.entityType}`)
                }
            ].filter(i => Boolean(i)) as Column<InzeratView>[]
        }}
        layoutDetail={()=><TabHelperNew<InzeratViewDetail> render={(data) =>
            <>
                <InzeratViewDetailFormBasicPartNew data={data} admin />
            </>}/>}
        crudConfig={{addEnabled:false, editEnabled:false, removeEnabled: false}}
        layoutFilter={() =><InzerceViewCiselnikFilter {...{lastBrowseDateDataKey: dateKey, search: true}}/>}
        hideSaveButton
        hideNewButtonOnEdit
    />
}
