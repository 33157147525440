import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {alpha, capitalize, Checkbox, IconButton, Menu, MenuItem, Tooltip, Typography} from "@material-ui/core";
import clsx from "clsx";
import {MTToolbarButton, MTToolbarProps} from "./MTToolbar";
import {makeStyles} from "@material-ui/core/styles";
import {exist} from "../../../common/utils/Util";
import DataStorage from "../../../common/DataStorage";
import {DGContext} from "./DataGrid";


const useStyles = makeStyles((theme) => ({
    formControlLabel: {
        margin: 0,
        padding: 0,
        paddingRight: '0.4rem',
    },
    checkBoxTitle: {
        fontSize: '10pt'
    },
    /* Styles applied to the root element. */
    root: {
        color: theme.palette.text.secondary,
    },
    /* Pseudo-class applied to the root element if `checked={true}`. */
    checked: {},
    /* Pseudo-class applied to the root element if `disabled={true}`. */
    disabled: {},
    /* Pseudo-class applied to the root element if `indeterminate={true}`. */
    indeterminate: {},
    /* Styles applied to the root element if `color="primary"`. */
    colorPrimary: {
        '&$checked': {
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        '&$disabled': {
            color: theme.palette.action.disabled,
        },
    },
    /* Styles applied to the root element if `color="secondary"`. */
    colorSecondary: {
        '&$checked': {
            color: theme.palette.type === 'dark' ? '#fff' : theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.type === 'dark' ? alpha('#fff', 0.2) : alpha('#a3a3a3', 0.2),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        '&$disabled': {
            color: theme.palette.action.disabled,
        },
    }
}))

export const ColumnsButton = (props:MTToolbarProps) => {
    const [columnsButtonAnchorEl, setColumnsButtonAnchorEl] = useState<HTMLButtonElement>();
    const classes = useStyles();
    const {t} = useTranslation();
    const color = 'secondary';
    const storageKey = `datagrid_${props.datagridId}`;
    const sessionData = DataStorage.get(storageKey, true, "session");
    const {getState, setState} = useContext(DGContext);

    useEffect(() => {
        for (let i = 0; i < props.getHiddenCols().length; i++) {
            const column = props.columns.find((c: any) => c.field === props.getHiddenCols()[i]);
            // Zabrani spadnutiu aplikacie v pripade ak ma uzivatel vo filtroch nieco co by tam nemalo byt (prohlizeni vs zadani)
            if (column !== undefined) {
                props.onColumnsChanged(column, true);
            }
        }

        if (exist(props.defaultHiddenColumns) && exist(sessionData) && !exist(JSON.parse(sessionData)?.hiddenColumns)) {
            for (let i = 0; i < props.defaultHiddenColumns.length; i++) {
                props.onColumnsChanged(props.columns.find((c: any) => c.field === props.defaultHiddenColumns[i]), true);
            }
        } else {
            setState(getState()); //Fixes loading of hidden columns
        }
        // eslint-disable-next-line
    }, [])

    return props.columnsButton && (
        <>
            <MTToolbarButton
                icon={<props.icons.ViewColumn/>}
                tooltip={t("MaterialTable.columns.showColumnsTitle")}
                onClick={(event) => {
                    setColumnsButtonAnchorEl(event.currentTarget)}
                }
            />
            <Menu
                anchorEl={columnsButtonAnchorEl}
                open={Boolean(columnsButtonAnchorEl)}
                onClose={() => setColumnsButtonAnchorEl( null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
                keepMounted
            >
              <MenuItem
                  key={"text"}
                  disabled
                  style={{
                      opacity: 1,
                      fontWeight: 600,
                      fontSize: 12,
                  }}
              >
                  <Typography variant='caption' style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                  }}>{t("MaterialTable.columns.menuTitle")}</Typography>
              </MenuItem>
                {props.columns.map((col: any) => {
                    return (
                        <li key={col.tableData.id}>
                            <MenuItem
                                className={classes.formControlLabel}
                                component="label"
                                htmlFor={`column-toggle-${col.tableData.id}`}
                                disabled={(exist(col.removable) && col.removable === false) || props.requiredColumns?.some(rc => rc === col.field)}
                            >
                                <Checkbox
                                    classes={{
                                        // @ts-ignore
                                        root: clsx(classes.root, classes[`color${capitalize(color)}`]),
                                        checked: classes.checked,
                                        disabled: classes.disabled,
                                    }}
                                    color={color}
                                    checked={!col.hidden}
                                    size={"small"}
                                    id={`column-toggle-${col.tableData.id}`}
                                    onChange={() => {
                                        const newState = !col.hidden
                                        props.onColumnsChanged(col, newState);
                                        if (props.connectedCols) {
                                            const defArray = props.connectedCols.filter(cc => cc.cols.some(c => c === col.field));
                                            if (defArray.length !== 0) {
                                                const cf: string[] = []
                                                for (let i = 0; i < defArray.length; i++) {
                                                    const reducedArray = defArray[i].cols.filter(c => c !== col.field);
                                                    for (let j = 0; j < reducedArray.length; j++) {
                                                        if (!cf.some(x => x === reducedArray[j])) {
                                                            const changedCol = props.columns.find((c: any) => c.field === reducedArray[j]);
                                                            if (changedCol) props.onColumnsChanged(changedCol, newState);
                                                            cf.push(reducedArray[j])
                                                        }
                                                    }
                                                }

                                            }
                                        }
                                    }}
                                />
                                <span className={classes.checkBoxTitle}>{col.tooltip ?? col.title}</span>
                            </MenuItem>
                        </li>
                    );
                })}
            </Menu>
          </>
    );
}
