import {useEvaluateFieldsVisibility} from "../../../../../common/logic/visibility-logic";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import React, {useCallback, useState} from "react";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {useHistory} from "react-router";
import {CrudOperationType, CrudUpdate, CrudUpdateEvent} from "../../../../model/CrudUpdate";
import {useCodeBookControllerContextNew} from "../../../../raal_components/controller/CodeBookController";
import {Inzerat, InzeratViewDetail} from "../../../../model/Inzerat";
import {useStompSubscribe} from "../../../../../common/utils/Websocket";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {ZadavatelHeaderInfoView} from "../../ZadavatelHeaderInfoView";
import {exist, formatPrice} from "../../../../../common/utils/Util";
import {KontaktyView} from "../KontaktyView";
import {ZadavatelBasicInfoView} from "../../ZadavatelBasicInfoView";
import {FormPartBox} from "../prohlizeni/FormPartBox";
import {httpEndpoint} from "../../../../../common/utils/HttpUtils";
import {ArchiveRecord} from "../prohlizeni/ModalArchiveViewRecord";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {ExportButtons} from "../../../../raal_components/grid/MTExportContainer";
import {OfferActionsPart} from "../prohlizeni/OfferActionsPart";
import {OfferArchiveActionsPart} from "../prohlizeni/OfferArchiveActionsPart";
import {ArchivPoznamkyDial} from "../archiv/ArchivPoznamkyDial";
import Avatar from "@material-ui/core/Avatar";
import {AttachMoney, Description, EventAvailable} from "@material-ui/icons";
import {TextTuple} from "../../../../../common/component/form/TextTuple";
import {useCodeBookDetailContextNew} from "../../../../raal_components/controller/CodeBookDetail";
import {ComponentMode} from "../../../../routes";

type InzeratDetailFormPartProps = {
    data: InzeratViewDetail,
    admin?: boolean,
    archive?: boolean,
    viewArchive?: boolean
    history?: boolean

}

export function InzeratViewDetailFormBasicPart(props: InzeratDetailFormPartProps) {
    const ev = useEvaluateFieldsVisibility(props.data.uzivatel.provozovna, props.data.uzivatel.provozovna?.firma, props.admin);
    const {classes} = useStyleContext();
    const {data} = props;
    const theme = useTheme();
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState(false);
    const [edited, setEdited] = useState(false);
    const [showConfirm] = useConfirmDialog();
    const {push} = useHistory();
    const stompCallback = useCallback((data: CrudUpdate) => {
        const stompProcData = CrudUpdateEvent.of(data, true);
        setDisabled(stompProcData.crudOperationType === CrudOperationType.REMOVE && (stompProcData.entity?.id === props.data.id));
        setEdited(stompProcData.crudOperationType === CrudOperationType.UPDATE && (stompProcData.entity?.id === props.data.id));
        // eslint-disable-next-line
    }, []);
    const {exportConfig, dataGridRef, replaceDetailForm} = useCodeBookControllerContextNew<Inzerat>();
    const {closeDetail} = useCodeBookDetailContextNew<Inzerat>()

    useStompSubscribe<CrudUpdate>('/crud-inzerat', {clazz: CrudUpdate, callback: stompCallback, userOnly: false});

    return ev && props.data.uzivatel.provozovna && (
        <div style={disabled ? {
            opacity: 0.9,
            padding: 20,
            backgroundColor: theme.palette.type === 'dark' ? "#222222" : "#dddddd",
            borderRadius: 10
        } : null}>
            {disabled || edited ? <Grid item style={{marginBottom: '1rem'}}><Alert
                action={edited ?
                    <Button color="inherit" size="small" onClick={() => {
                        if (edited) {
                            setEdited(false);
                            replaceDetailForm();
                            dataGridRef.current?.onConfirmChangedData(props.data);
                        } else {
                            closeDetail();
                        }
                    }}>
                        {edited ? t('Buttons.Refresh') : t('Buttons.Close')}
                    </Button> : null
                }
                severity={edited ? 'warning' : 'error'}>{edited ? t("FormLocalization.DataWasEdited") : t("FormLocalization.DataWasRemoved")}</Alert></Grid> : null}
            <div style={disabled ? {pointerEvents: 'none'} : null}>
                <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                    {(() => {
                        if ((props.data.rychlyKontakt != null || exist(props.data.uzivatel?.jmeno) || (props.data.dispecer != null && props.data.dispecer.id != null)) || ev(["provozovna.email", "provozovna.telCislaKontaktu"])) {
                            return <Grid item lg={8} sm={12} xs={12}>
                                <InzeratDetailFormBasicPartBody data={data} admin={props.admin} archive={props.archive}
                                                                viewArchive={props.viewArchive}/>
                            </Grid>
                        } else {
                            return <Grid item lg={12} sm={12} xs={12}>
                                <InzeratDetailFormBasicPartBody data={data} admin={props.admin} archive={props.archive}
                                                                viewArchive={props.viewArchive}/>
                            </Grid>
                        }
                    })()
                    }
                    <Grid item lg={4} sm={12} xs={12}>
                        <Card style={{height: "100%"}} className={classes.sectionBackground}>
                            <KontaktyView data={props.data} admin={props.admin}/>
                        </Card>
                    </Grid>
                </Grid>

                {props.viewArchive && !props.admin ?
                    <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                        <Grid item lg={12} sm={12} xs={12}>
                            <FormPartBox title={t("Actions")}>
                                <Grid item container spacing={1}>
                                    <Grid item>
                                        <Button variant={"contained"} color={"primary"}
                                                onClick={() => {
                                                    showConfirm({
                                                        onConfirm: async () => {
                                                            const result = await httpEndpoint<ArchiveRecord>(ArchiveRecord, `user/inzerat-prohlizeni-archiv/${props.data.id}`, {method: "DELETE"});
                                                            if (result.response.status === 200) {
                                                                showSnack({
                                                                    title: t("FormLocalization.DataRemoved"),
                                                                    severity: "success"
                                                                });
                                                                push('/archiv-prohlizeni/inzerce');
                                                            } else {
                                                                showSnack({
                                                                    title: t("Errors.CouldNotDelete"),
                                                                    severity: "error"
                                                                });
                                                            }
                                                        },
                                                        body: t("Default.DeleteText"),
                                                        title: t("Default.DeleteTitle"),
                                                        buttons: {confirm: t("Buttons.Delete")}
                                                    });

                                                }}>
                                            {t("Buttons.Delete")}</Button>
                                    </Grid>
                                    {exportConfig ?
                                        <ExportButtons data={props.data} exportConfig={exportConfig}/> : null}
                                </Grid>
                            </FormPartBox>
                        </Grid>
                    </Grid>
                    : null}

                {!props.admin && !props.viewArchive && !props.history &&
                    <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                        <Grid item xs>
                            {!props.archive && <OfferActionsPart data={data}>
                                {exportConfig ? <ExportButtons data={props.data} exportConfig={exportConfig}/> : null}
                            </OfferActionsPart>}
                            {props.archive && <OfferArchiveActionsPart data={data}/>}
                        </Grid>
                    </Grid>
                }

                {props.archive && !props.admin && !props.viewArchive && !props.history &&
                    <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                        <Grid item xs>
                            <ArchivPoznamkyDial url={`user/inzerat-archiv/${data.id}/poznamka`}/>
                        </Grid>
                    </Grid>
                }
                {props.archive && !props.admin && props.viewArchive && !props.history &&
                    <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                        <Grid item xs>
                            <ArchivPoznamkyDial url={`user/inzerat-prohlizeni-archiv/${data.id}/poznamka`}/>
                        </Grid>
                    </Grid>}
                <ZadavatelHeaderInfoView userData={props.data.uzivatel} id={props.data.id} admin={props.admin}/>
                <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                    <Grid item lg={12} sm={12} xs={12}>
                        <ZadavatelBasicInfoView userData={data.uzivatel} admin={props.admin}/>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export function InzeratViewDetailFormBasicPartNew(props: InzeratDetailFormPartProps) {
    const ev = useEvaluateFieldsVisibility(props.data.uzivatel.provozovna, props.data.uzivatel.provozovna?.firma, props.admin);
    const {classes} = useStyleContext();
    const {data} = props;
    const theme = useTheme();
    const {t} = useTranslation();
    const [disabled, setDisabled] = useState(false);
    const [edited, setEdited] = useState(false);
    const [showConfirm] = useConfirmDialog();
    const {push} = useHistory();
    const stompCallback = useCallback((data: CrudUpdate) => {
        const stompProcData = CrudUpdateEvent.of(data, true);
        setDisabled(stompProcData.crudOperationType === CrudOperationType.REMOVE && (stompProcData.entity?.id === props.data.id));
        setEdited(stompProcData.crudOperationType === CrudOperationType.UPDATE && (stompProcData.entity?.id === props.data.id));
        // eslint-disable-next-line
    }, []);
    const {exportConfig, dataGridRef, replaceDetailForm} = useCodeBookControllerContextNew<Inzerat>();
    const {closeDetail} = useCodeBookDetailContextNew<Inzerat>()

    useStompSubscribe<CrudUpdate>('/crud-inzerat', {clazz: CrudUpdate, callback: stompCallback, userOnly: false});

    return ev && props.data.uzivatel.provozovna && (
        <div style={disabled ? {
            opacity: 0.9,
            padding: 20,
            backgroundColor: theme.palette.type === 'dark' ? "#222222" : "#dddddd",
            borderRadius: 10
        } : null}>
            {disabled || edited ? <Grid item style={{marginBottom: '1rem'}}><Alert
                action={edited ?
                    <Button color="inherit" size="small" onClick={() => {
                        if (edited) {
                            setEdited(false);
                            replaceDetailForm();
                            dataGridRef.current?.onConfirmChangedData(props.data);
                        } else {
                            closeDetail();
                        }
                    }}>
                        {edited ? t('Buttons.Refresh') : t('Buttons.Close')}
                    </Button> : null
                }
                severity={edited ? 'warning' : 'error'}>{edited ? t("FormLocalization.DataWasEdited") : t("FormLocalization.DataWasRemoved")}</Alert></Grid> : null}
            <div style={disabled ? {pointerEvents: 'none'} : null}>
                <ZadavatelHeaderInfoView userData={props.data.uzivatel} id={props.data.id} admin={props.admin}/>
                <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                    {(() => {
                        if ((props.data.rychlyKontakt != null || exist(props.data.uzivatel?.jmeno) || (props.data.dispecer != null && props.data.dispecer.id != null)) || ev(["provozovna.email", "provozovna.telCislaKontaktu"])) {
                            return <Grid item lg={8} sm={12} xs={12}>
                                <InzeratDetailFormBasicPartBody data={data} admin={props.admin} archive={props.archive}
                                                                viewArchive={props.viewArchive}/>
                            </Grid>
                        } else {
                            return <Grid item lg={12} sm={12} xs={12}>
                                <InzeratDetailFormBasicPartBody data={data} admin={props.admin} archive={props.archive}
                                                                viewArchive={props.viewArchive}/>
                            </Grid>
                        }
                    })()
                    }
                    <Grid item lg={4} sm={12} xs={12}>
                        <Card style={{height: "100%"}} className={classes.sectionBackground}>
                            <KontaktyView data={props.data} admin={props.admin}/>
                        </Card>
                    </Grid>
                </Grid>
                {props.viewArchive && !props.admin ?
                    <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                        <Grid item lg={12} sm={12} xs={12}>
                            <FormPartBox title={t("Actions")}>
                                <Grid item container spacing={1}>
                                    <Grid item>
                                        <Button variant={"contained"} color={"primary"}
                                                onClick={() => {
                                                    showConfirm({
                                                        onConfirm: async () => {
                                                            const result = await httpEndpoint<ArchiveRecord>(ArchiveRecord, `user/inzerat-prohlizeni-archiv/${props.data.id}`, {method: "DELETE"});
                                                            if (result.response.status === 200) {
                                                                showSnack({
                                                                    title: t("FormLocalization.DataRemoved"),
                                                                    severity: "success"
                                                                });
                                                                push('/archiv-prohlizeni/inzerce');
                                                            } else {
                                                                showSnack({
                                                                    title: t("Errors.CouldNotDelete"),
                                                                    severity: "error"
                                                                });
                                                            }
                                                        },
                                                        body: t("Default.DeleteText"),
                                                        title: t("Default.DeleteTitle"),
                                                        buttons: {confirm: t("Buttons.Delete")}
                                                    });

                                                }}>
                                            {t("Buttons.Delete")}</Button>
                                    </Grid>
                                    {exportConfig ?
                                        <ExportButtons data={props.data} exportConfig={exportConfig}/> : null}
                                </Grid>
                            </FormPartBox>
                        </Grid>
                    </Grid>
                    : null}
                {!props.admin && !props.viewArchive && !props.history &&
                    <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                        <Grid item xs>
                            {!props.archive && <OfferActionsPart data={data}>
                                {exportConfig ? <ExportButtons data={props.data} exportConfig={exportConfig}/> : null}
                            </OfferActionsPart>}
                            {props.archive && <OfferArchiveActionsPart data={data}/>}
                        </Grid>
                    </Grid>}
                {!props.admin && !props.history &&
                    <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                        <Grid item xs>
                            <ArchivPoznamkyDial mode={ComponentMode.GridMode} nabidkaId={props.data?.id}
                                                nabidkaType={'I'} viewArchive={props.viewArchive}/>
                        </Grid>
                    </Grid>}
                <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                    <Grid item lg={12} sm={12} xs={12}>
                        <ZadavatelBasicInfoView userData={data.uzivatel} admin={props.admin}/>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

function InzeratDetailFormBasicPartBody(props: InzeratDetailFormPartProps) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const ev = useEvaluateFieldsVisibility(props.data.uzivatel.provozovna, props.data.uzivatel.provozovna?.firma, null);
    const {data} = props;

    const getPrice = () => {
        const price = `${data.cena && data.currency?.currencyCode ? formatPrice(data.cena, data.currency.currencyCode) : data.cena ?? '-'}`;
        return data.jednotka ? price + "/" + data.jednotka : price
    }


    return ev && props.data.uzivatel.provozovna && (
        <>
            <Card style={{height: "100%"}} className={classes.sectionBackground}>
                <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
                    <Box marginBottom={2}>
                        <Typography variant="h6">{t("Inzerce.OfferDetail")}</Typography>
                    </Box>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} spacing={3}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <ListItem>
                                    <ListItemAvatar><Avatar
                                        className={"avatar-offer"}><Description/></Avatar></ListItemAvatar>
                                    <ListItemText>
                                        <TextTuple title={t("Inzerce.AdText")} content={data.popis}/>
                                    </ListItemText>
                                </ListItem>
                            </Grid>

                            {!props.viewArchive &&
                                <>
                                    <Grid item lg={4} sm={4} xs={12}>
                                        <ListItem>
                                            <ListItemAvatar><Avatar
                                                className={"avatar-offer"}><AttachMoney/></Avatar></ListItemAvatar>
                                            <ListItemText>
                                                <TextTuple title={t("Inzerce.Price")} content={getPrice()}/>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>

                                    <Grid item lg={4} sm={4} xs={12}>
                                        <ListItem>
                                            <ListItemAvatar><Avatar
                                                className={"avatar-offer"}><EventAvailable/></Avatar></ListItemAvatar>
                                            <ListItemText>
                                                <TextTuple title={t("PVI.CreatedOn")}
                                                           content={data.datIns?.format("L LT")}/>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>

                                    <Grid item lg={4} sm={4} xs={12}>
                                        <ListItem>
                                            <ListItemAvatar><Avatar
                                                className={"avatar-offer"}><EventAvailable/></Avatar></ListItemAvatar>
                                            <ListItemText>
                                                <TextTuple title={t("PVI.Modified")}
                                                           content={data.modifiedOn?.format("L LT")}/>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>
                                </>
                            }

                            {props.viewArchive &&
                                <>

                                    <Grid item lg={3} sm={4} xs={12}>
                                        <ListItem>
                                            <ListItemAvatar><Avatar
                                                className={"avatar-offer"}><AttachMoney/></Avatar></ListItemAvatar>
                                            <ListItemText>
                                                <TextTuple title={t("Inzerce.Price")} content={getPrice()}/>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>

                                    <Grid item lg={3} sm={4} xs={12}>
                                        <ListItem>
                                            <ListItemAvatar><Avatar
                                                className={"avatar-offer"}><EventAvailable/></Avatar></ListItemAvatar>
                                            <ListItemText>
                                                <TextTuple title={t("PVI.CreatedOn")}
                                                           content={data.datIns?.format("L LT")}/>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>

                                    <Grid item lg={3} sm={4} xs={12}>
                                        <ListItem>
                                            <ListItemAvatar><Avatar
                                                className={"avatar-offer"}><EventAvailable/></Avatar></ListItemAvatar>
                                            <ListItemText>
                                                <TextTuple title={t("PVI.Modified")}
                                                           content={data.modifiedOn?.format("L LT")}/>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>


                                    <Grid item lg={3} sm={4} xs={12}>
                                        <ListItem>
                                            <ListItemAvatar><Avatar
                                                className={"avatar-offer"}><EventAvailable/></Avatar></ListItemAvatar>
                                            <ListItemText>
                                                <TextTuple title={t("Preprava.datumArchivace")}
                                                           content={props.admin ? data.deletedOn?.format("L LT") : data.datArch?.format("L LT")}/>
                                            </ListItemText>
                                        </ListItem>
                                    </Grid>

                                </>
                            }


                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}
