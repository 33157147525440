import * as React from "react";
import {useRef} from "react";
import {Dial} from "../../../../raal_components/dial/Dial";
import {useTranslation} from "react-i18next";
import {Vozidlo} from "../../../../model/Vozidlo";
import {
    exportableFieldsAdminView,
    getExportableProps,
    getFilterVersion,
    useVPPdfLayout
} from "../_vp/PrepravaAVozidlaShared";
import {VPFilter, VPListVozidloAdmin,} from "../../../../model/PrepravaVozidlo";
import {useViewColumns} from "../_vp/VPColumns";
import {FilterForm} from "../_vp/VPFilter";
import {ExtendedViewNew} from "../_vp/VPExtendedView";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {useEnumCiselnikSelect, useEnumeratedSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {useCiselnikValues, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {globalStyles} from "../../../../context/ThemeModeContext";
import {InvalDuvodVozidlo, VolneVozySearchEntityType} from "../../../../model/CommonTypes";
import {NabidkaType} from "../../../../model/NabidkaType";
import {useAppContext} from "../../../../context/AppContext";
import {prepareCols} from "./PrepravyCiselnikSearch";
import {
    CISELNIK_DRUH,
    DEFAULT_ADVANCE_VALUE,
    DEFAULT_RANGE_VALUE,
    PARAM_ADVANCE_KEY,
    PARAM_RANGE_KEY
} from "../prohlizeni/VlastniVozyCiselnikView";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {RouteComponentProps} from "../../../../routes";
import {getEntityTypeUrl} from "./SearchFunctions";

export function VlastniVozyCiselnikSearch(props: RouteComponentProps) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const [createColumns] = useViewColumns(CISELNIK_DRUH, false, undefined, undefined, ["datIns", "uzivatel"], PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, true, {search: true, typ: NabidkaType.VOZIDLO, refactor: true}, true);
    const dateKey = "vozy-search-browse-date";
    const endpoint = "admin/vozidlo-search-union";
    const invalDuvSelect = useEnumCiselnikSelect({isClearable: true, ciselnikTyp: CiselnikTyp.V, enm: InvalDuvodVozidlo, enmName: "Enumerations.InvalDuvodVozidlo"});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.V);
    const searchTypeSelect = useEnumeratedSelect(VolneVozySearchEntityType, "SearchEnum.VolneVozy", "string", {isClearable:true});
    const dtGrid = useRef<DataGridExposed<VPListVozidloAdmin, VPFilter>>();
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {pdfLayout} = useVPPdfLayout(user, false);

    return <Dial<VPListVozidloAdmin, VPFilter, Vozidlo>
        mode={props.mode}
        lastBrowsedDateKey={dateKey}
        logActivity
        gridRef={dtGrid}
        getModificationDate={data => data.modifiedOn}
        config={{
            lockSupport: {enabled: false},
            getDetailIdUrlPart: (data) : string =>
                (`${getEntityTypeUrl(data.entityType)}${data.id}`),
            overflowHidden: true,
            exportConfig: {
              exportable: true,
              exportAll: true,
              endpoint: endpoint,
              getExportDetailIdUrlPart: (data) =>
                  ({endpoint: getEntityTypeUrl(data.entityType), id: data.id}),
              fileName: "export_vyhledavani_volne_vozy",
              translationPrefix: ['Preprava', 'User', 'Archive'],
              formats: ["pdf", "xls", "csv", "xml"],
              exportAllFormats: ["csv"],
              exportableProps: getExportableProps(exportableFieldsAdminView, true),
              pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
              extendedProps: [
                  {
                      type: ["csv", "xls"],
                      addExtendedProps: (data) => {
                          return {
                              provozovna: data.uzivatel?.provozovna?.kod,
                              archived: data.deletedOn
                          }
                      }
                  }
              ],
              formattedProps: [
                  {
                      type: ["csv", "xls", "pdf"],
                      field: "druhy",
                      format: (data) => {
                          return druhyJoined(data)
                      }
                  },
                  {
                      type: ["csv", "xls", "pdf"],
                      field: "invalDuv",
                      format: (data) => {
                          return locFun("Enumerations.InvalDuvodPreprava", InvalDuvodVozidlo, data)
                      }
                  },
                  {
                      type: ["csv", "xls", "pdf"],
                      field: "rychlyKontakt",
                      format: (data) => {
                          return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                      }
                  }
              ]
            },
            options: {
              rowStyle: (data: any) => {
                  if (!data.valid)
                      return globalStyles.rowStyleAlert;
                  if(data.duplicita){
                      return globalStyles.rowStyleDuplicated;
                  }
                  return undefined;
              }
            },
            version:getFilterVersion(),
            hideDefaultDummyAction:false,
            tableTitle : t("Dials.VolneVozy"), endpoint:endpoint, clazz : VPListVozidloAdmin, filtering:true,
            hideAddNewActions: true,
            columns: prepareCols(createColumns, t, searchTypeSelect, yesNoSelect, locFun, invalDuvSelect),
        }}
        hideSaveButton={true}
        hideNewButtonOnEdit={true}
        crudConfig={{editEnabled:false, addEnabled:false, removeEnabled: false}}
        layoutFilter={() => <FilterForm {...{lastBrowseDateDataKey: dateKey, typCiselniku:CISELNIK_DRUH, preprava: false, viewing: true, defaultRange:DEFAULT_RANGE_VALUE, typAdvance: PARAM_ADVANCE_KEY, defaultAdvance: DEFAULT_ADVANCE_VALUE, search: true}}/>}
        modalHeaderName={()=>t("Dials.VolneVozy")}
        filterClazz={VPFilter}
        clazzDetail={Vozidlo}
        layoutDetail={()=><TabHelperNew<Vozidlo> render={(data)=>
            <>
                <ExtendedViewNew ciselnikTyp={CISELNIK_DRUH} data={data} admin/>
            </>}
        />}
    />;
}
