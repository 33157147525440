import {useTranslation} from "react-i18next";
import React, {MutableRefObject, ReactElement, useEffect, useImperativeHandle, useState} from "react";
import exportFromJSON, {ExportType} from "export-from-json";
import {Box, Button, CircularProgress, Grid, Menu, MenuItem} from "@material-ui/core";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MTToolbarButton, MTToolbarProps} from "./MTToolbar";
import _ from "lodash";
import moment from "moment";
import {deepCompareIsDate, exist, flattenObject, isObjectEmpty} from '../../../common/utils/Util';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import {useFetchCustom} from '../../../common/utils/HttpUtils';
import {SimpleValue} from "../../model/SimpleValue";
// @ts-ignore
import htmlToPdfmake from "html-to-pdfmake"
import ReactDOMServer from 'react-dom/server'
import {Typography} from "@mui/material";
import {PubSub, useSubscribe} from "use-pubsub-js";
import {GenericMap} from "../../../index.d";
import {AssetCache} from "../../../AssetCache";
import {MTMultiColumnActionButtonsExposed} from "./MTMultiColumnAction";
import {utils, writeFile} from 'xlsx';
import {useAppContext} from "../../context/AppContext";
import {UserRole} from "../../model/User";

import { Mapper } from "../../../common/utils/objectmapper/Mapper";
import {SelectItem, useMTExportSelect} from "./MTExportSelect";
import {MTPrintTable} from "./MTPrintTable";

export type ExportTypeFormat = "pdf" | "xml" | "csv" | "xls" | "print";

export type DataProps = {
    type: ExportTypeFormat | ExportTypeFormat[]
    fields: string[]
}

export type ExtendedProps = {
    type: ExportTypeFormat | ExportTypeFormat[]
    translationPrefix?: string[]
    addExtendedProps?: (data: any) => any
}

export type FormattedProps = {
    type: ExportTypeFormat | ExportTypeFormat[]
    field: string
    format: (data: any) => string
}

export type ExternalDataProps = {
    type: ExportTypeFormat | ExportTypeFormat[]
    asyncData?: (data: any) => Promise<any>
}

const excelStringFields = ["psc", "ic", "dic", "kod", "provozovna"]

export type ExportConfig = {
    formats: ExportTypeFormat[]
    exportAll?: boolean
    columnSelectFormats?: ExportTypeFormat[]
    columnSelectDefaultFields?: string[]
    exportAllFormats?: ExportTypeFormat[]
    exportable: boolean
    exportDetailOnly?: boolean
    translationPrefix: string | string[]
    endpoint: string
    fileName?: string
    exportableProps?: DataProps[]
    excludedProps?: DataProps[]
    formattedProps?: FormattedProps[]
    detailTitle?: string,
    extendedProps?: ExtendedProps[],
    getExportDetailIdUrlPart?: (data: any) => ExportDetail
    pdfLayout?: (data: any, fields: any, pageBreak: boolean, index: number, origin: any, extendedData: any) => ReactElement
    externalData?: ExternalDataProps[]
    defaultQueryParameters?: GenericMap
    extendedDataEndpoint?: (data: any) => Promise<any>
    extendedDataMapper?: (data: any) => any
    filterClazz?:{new(): any}
}

export type ExportDetail = {
    endpoint: string,
    id: number
}

export const checkComma = (data: string) => {
    if (!data || data.length === 0 || data.indexOf(',') === 0) return data;
    const str = data.replaceAll(', ', ',');
    return str.replaceAll(',', ', ');
}

const useExportData = (exportConfig?: ExportConfig, exportAll?: boolean, getFilter?: () => any) => {
    const {fetch} = useFetchCustom<SimpleValue, string>({endpoint: arg => `${exportConfig?.endpoint}${arg}export`})
    const {fetch: fetchList} = useFetchCustom<SimpleValue>({endpoint: `${exportConfig?.endpoint}`})
    const {i18n} = useTranslation();
    const pdfMake = require("pdfmake/build/pdfmake");
    const pdfFonts = require("pdfmake/build/vfs_fonts");
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const {hasUserRole} = useAppContext();

    const getEndpointsWithIds = (data: any, exportAll = false): { [endpoint: string]: number[] } => {
        let result: { [endpoint: string]: number[] } = {};
        let defaultEndpoint = "/";
        for (const record of data) {
            if (exportAll || record.tableData?.checked) {
                let detail = exportConfig.getExportDetailIdUrlPart?.(record)
                    ?? {endpoint: defaultEndpoint, id: record.id};
                if (!result[detail.endpoint]) result[detail.endpoint] = [];
                result[detail.endpoint].push(detail.id);
            }
        }
        return result;
    }

    const fetchDataForExport = async (endpointsWithIds: {
        [endpoint: string]: number[]
    }, page?: number): Promise<any> => {
        let fetchPromises = [];
        for (const [endpoint, ids] of Object.entries(endpointsWithIds)) {
            if (ids.length !== 0) {
                let log = {};
                if (page) {
                    log = page === 1 ? {exportType: 'ALL_FIRST_PAGE'} : {exportType: 'ALL_NEXT_PAGE'}
                }
                let arg = endpoint;
                if (!arg.endsWith("/")) arg = arg + "/";
                if (!arg.startsWith("/")) arg = "/" + arg;

                const fetchPromise = fetch({params: {id: ids.join(','), ...log}, arg: arg})
                    .then(res => Array.isArray(res) ? res : [])
                    .catch(e => []);
                fetchPromises.push(fetchPromise);
            }
        }
        try {
            return (await Promise.all(fetchPromises)).flat();
        } catch (e) {
            return [];
        }
    }
    /**
     * Funkcia na odstranenie undefined hodnot a undefined properties objektov
     * @param obj
     */
    const deepClean = (obj: any) => {
        return _.omitBy(_.mapValues(obj, (value) => {
            // Recursively clean objects, or just return the value if it's not an object
            if (_.isObject(value)) {
                const cleaned : any = deepClean(value);
                return _.isEmpty(cleaned) ? undefined : cleaned; // Remove empty objects
            }
            return value;
        }), (val) => _.isUndefined(val) || _.isNull(val)); // Omit properties that are undefined or null
    };

    const getFilterData = () => {
        const query = getFilter();
        let defaultQueryParams: GenericMap = exportConfig.defaultQueryParameters;

        let map;
        if (exportConfig.filterClazz){
            let mapper = new Mapper<any>({constructor: exportConfig.filterClazz});
            map = mapper.writeValueAsJson(query.filters);
        } else {
            map = query?.filters as GenericMap
        }

        map = {..._.omitBy(defaultQueryParams, _.isNil), ..._.omitBy(map, _.isNil)};
        map = deepClean(map) // Zabezpeci ze sa do requestu nepridaju aj undefined filtre
        return flattenObject(map);
    }

    const fetchListForExportAll = async (): Promise<any[]> => {
        const query = getFilter();
        const map = getFilterData();
        let page = 1;
        const dataCount = 100;
        let itemsCount = 0;
        let data: any[] = []
        do {
            try {
                const res: any = await fetchList({
                    params: {
                        ...map,
                        orderBy: query.orderBy,
                        orderDirection: query.orderDirection,
                        page: page,
                        pageSize: dataCount
                    }
                });
                if (res.pages && res.page) PubSub.publish('updateProgress', (res.page / res.pages) * 100);
                if (res.list) {
                    const details = await fetchDataForExport(getEndpointsWithIds(res.list, true), page);
                    data.push(...details)
                    itemsCount = details.length;
                    page++;
                } else {
                    itemsCount = 0;
                }
            } catch {
                itemsCount = 0;
                data = [];
            }
        } while (dataCount === itemsCount)

        return data;
    }

    function exportJsonToXls(jsonData: any, filePath: string, fields: any) {
        // Convert JSON data to a worksheet
        const worksheet = utils.json_to_sheet(jsonData);

        // Replace keys with actual column names
        utils.sheet_add_aoa(worksheet, [Object.values(fields)])

        // Create a new workbook and append the worksheet
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, filePath);

        // Write the workbook to a file in .xlsx format
        writeFile(workbook, `${filePath}.xls`, {bookType: "xls"});
    }

    const exportToPdf = (data: any[], fileName: string, fields: any, detailTitle: string, origin: any[], extendedData: any) => {
        const dataFields = () => {
            const items = [];
            const keys = Object.keys(fields);

            for (let d = 0; d < data.length; d++) {
                if (!exportConfig.pdfLayout) {
                    items.push(<div key={`table_${d}`}
                                    className={d !== 0 ? "pdf-pagebreak-before" : null}>{detailTitle ?
                        <h1>{detailTitle}</h1> : <span> </span>}</div>);
                    const tdItems = [];
                    for (let i = 0; i < keys.length; i++) {
                        tdItems.push(<tr key={`${d}_${i}`}>
                            <td><span
                                style={{fontWeight: "bold"}}>{`${fields[keys[i]]}: `}</span><span>{data[d][keys[i]] ? checkComma(data[d][keys[i]].toString()) : '-'}</span>
                            </td>
                            {exist(keys[i + 1]) ? <td><span
                                style={{fontWeight: "bold"}}>{`${fields[keys[i + 1]]}: `}</span><span>{data[d][keys[i + 1]] ? checkComma(data[d][keys[i + 1]].toString()) : '-'}</span>
                            </td> : <td>&nbsp;</td>}
                        </tr>);
                        i++;
                    }
                    items.push(<table data-pdfmake="{'widths':['*','*']}" key={`table_${d}`}>{tdItems}</table>)
                } else {
                    items.push(<div key={`table_${d}`}
                                    className={d !== 0 ? "pdf-pagebreak-before" : null}>&nbsp;</div>);
                    items.push(exportConfig.pdfLayout(data[d], fields, d !== 0, d, origin[d], extendedData));
                }
            }

            return items;

        }

        const createComponent = () => {
            return ReactDOMServer.renderToStaticMarkup(
                <div>{dataFields()}</div>
            )
        }
        const html = htmlToPdfmake(`${createComponent()}`,
            {
                tableAutoSize: true,
                defaultStyles: {
                    div: {marginBottom: 3},
                    table: {marginBottom: 0}
                }
            });

        const docDefinition = {
            content: [html],
            images: {
                logo: AssetCache.Image.Logo
            },
            styles: {
                'font-9': {
                    fontSize: 9
                },
                'font-10': {
                    fontSize: 10
                }
            },
            pageBreakBefore: function (currentNode: any) {
                return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
            }
        };
        pdfMake.createPdf(docDefinition).download(fileName);
    }

    const getLocalizedText = (p: string, extProps?: ExtendedProps) => {
        let text = null;
        const translationPref = exportConfig?.translationPrefix instanceof Array ? [...exportConfig.translationPrefix] : [exportConfig.translationPrefix];
        if (translationPref.length !== 0) {
            for (let i = 0; i < translationPref.length; i++) {
                const translation: any = {};
                if (i18n.exists(`${translationPref[i]}`)) {
                    const i18nData: any = i18n.t(translationPref[i], {returnObjects: true});
                    const keys = Object.keys(i18nData);
                    for (let j = 0; j < keys.length; j++) {
                        const key = keys[j].toString().toLowerCase();
                        translation[key] = i18nData[keys[j]];
                    }
                }
                if (exist(translation[p.toLowerCase()])) {
                    text = translation[p.toLowerCase()];
                    break;
                }
            }
        }

        if (!text && extProps?.translationPrefix && extProps?.translationPrefix?.length !== 0) {
            for (let i = 0; i < extProps.translationPrefix.length; i++) {
                const translation: any = {};
                if (i18n.exists(`${extProps.translationPrefix[i]}`)) {
                    const i18nData: any = i18n.t(extProps.translationPrefix[i], {returnObjects: true});
                    const keys = Object.keys(i18nData);
                    for (let j = 0; j < keys.length; j++) {
                        const key = keys[j].toString().toLowerCase();
                        translation[key] = i18nData[keys[j]];
                    }
                }

                if (exist(translation[p.toLowerCase()])) {
                    text = translation[p.toLowerCase()];
                    break;
                }
            }
        }

        return text ?? p;
    }

    const exportSelect = useMTExportSelect(/*exportConfig, getLocalizedText*/);
    const exportData = async (data: any, exportType: ExportType | "pdf" | "print", skipFetch?: boolean, extendedData?: any): Promise<any> => {
        let filteredData: any[];
        const dataForExport: any[] = [];
        const fileName = exportConfig.fileName ? `${exportType}_${exportConfig.fileName}` : `${exportType}_data`;
        let fields: any = {};
        let exportableProps = exportConfig?.exportableProps?.find(ep => ep.type && ((ep.type && ep.type instanceof Array && ep.type.some(t => t === exportType)) || ep.type === exportType))?.fields ?? [];
        const extProps = exportConfig?.extendedProps?.find(ep => ep.type && ((ep.type instanceof Array && ep.type.some(t => t === exportType)) || ep.type === exportType)) ?? null;
        const externalProps = exportConfig?.externalData?.find(ep => ep.type && ((ep.type instanceof Array && ep.type.some(t => t === exportType)) || ep.type === exportType)) ?? null;

        if(exportConfig?.columnSelectFormats?.includes(exportType as ExportTypeFormat)){

            let selectItems: SelectItem[] = exportableProps.map(id => ({
                id: id,
                label: getLocalizedText(id, extProps),
                enabled: (exportConfig?.columnSelectDefaultFields || []).includes(id)
            }));

            const selectedItems = await exportSelect.showExportSelect(selectItems);
            if(selectedItems == null) return false;
            exportableProps = selectedItems.map((item: SelectItem)  => item.id);
        }

        if (!exportAll) {
            filteredData = skipFetch ? [data] : await fetchDataForExport(getEndpointsWithIds(data));
        } else {
            filteredData = await fetchListForExportAll();
        }

        // Nastavení pořadí zobrazení
        if (exportType !== 'xml') {
            for (let i = 0; i < exportableProps.length; i++) {
                fields = {
                    ...fields,
                    [exportableProps[i]]: exportConfig.translationPrefix && getLocalizedText(exportableProps[i], extProps)
                };
            }
        }
        const excludedProps = exportConfig?.excludedProps?.find(ep => ep.type && ((ep.type instanceof Array && ep.type.some(t => t === exportType)) || ep.type === exportType))?.fields ?? [];

        for (let i = 0; i < filteredData.length; i++) {
            // Exportable props
            const notExportable = exportableProps.length !== 0 ? _.difference(Object.keys(filteredData[i]), exportableProps) : [];
            // Excluded fields
            let o: any = _.omit(filteredData[i], [...notExportable, ...excludedProps, 'tableData']);

            // Extended props
            if (extProps) {
                const tempProps = extProps.addExtendedProps(filteredData[i]);
                o = _.merge(o, tempProps)
            }

            // External props
            if (externalProps) {
                const tempProps = await externalProps.asyncData(filteredData[i])
                o = _.merge(o, tempProps)
            }

            const keys = Object.keys(o);
            for (let j = 0; j < keys.length; j++) {
                const p = keys[j];
                // Moment to ISO format
                if (o[p] instanceof moment) {
                    o[p] = o[p].toISOStringWithMillis();
                }
                // Number format with comma
                if (o[p] && typeof o[p] === 'number') {
                    o[p] = o[p].toString().replace('.', ',');
                }

                // TODO - v pripade uzavretia 4226 zmazat
                // if (exportType === 'xls' && o[p] && excelStringFields.some(e => p.includes(e))) {
                //     o[p] = `="${o[p].toString()}"`;
                // }

                const custopFormat = exportConfig?.formattedProps?.find(fp => fp.type && ((fp.type instanceof Array && fp.type.some(t => t === exportType)) || fp.type === exportType) && fp.field === p);
                if (custopFormat) {
                    o[p] = custopFormat.format(o[p]);
                }

                if (exportType !== 'xml') {
                    if (exportableProps.length === 0) {
                        if (i === 0 || !exist(fields[p])) {
                            fields = {
                                ...fields,
                                [p]: exportConfig.translationPrefix && getLocalizedText(p, extProps)
                            };
                        }
                    }

                    // Boolean values TRUE
                    if (o[p] === true) o[p] = i18n.t('Default.Yes');

                    // Boolean values FALSE
                    if (o[p] === false) o[p] = '';

                    if (o[p] && o[p] instanceof Object && o[p].currencyCode !== undefined) o[p] = o[p].currencyCode;

                    // Null values
                    if (!exist(o[p])) {
                        o[p] = '';
                    }

                    // Users
                    if (o[p] && o[p] instanceof Object && (o[p].login !== undefined || o[p].jmeno !== undefined || o[p].email !== undefined)) {
                        const userData = [];
                        if (o[p].jmeno) userData.push(o[p].jmeno);
                        if (o[p].phoneNumbers && o[p].phoneNumbers.length !== 0) userData.push(o[p].phoneNumbers.join(', '));
                        if (o[p].email) userData.push(o[p].email);
                        o[p] = userData.join(', ');
                    }

                    // Test DateTime
                    if (o[p] && deepCompareIsDate(o[p])) {
                        o[p] = moment(o[p]).format('L LT');
                    }

                    // Clear object data
                    if (o[p] && o[p] instanceof Object) o[p] = '';
                }
            }

            if (exportableProps.length === 0) {
                dataForExport.push(o);
            } else {
                let dataRow = {};
                for (let i = 0; i < exportableProps.length; i++) {
                    dataRow = {
                        ...dataRow,
                        [exportableProps[i]]: o[exportableProps[i]]
                    }
                }
                dataForExport.push(dataRow);
            }
        }

        if(exportType === "print") {
            return {
                columns: exportableProps.map(prop => getLocalizedText(prop, extProps)),
                data: Object.values(dataForExport).map(obj => Object.values(obj))
            }
        }

        if (dataForExport.length !== 0) {
            // Na sheety pouzivame samostatny package nakolko predosly exportoval xls ako html
            if (exportType === 'xls') {
                if (!extendedData) {
                    // pridan if dle #4577 - kdyz ma zakaznik pouze roli uzivatel, tak nema mit v exportu sloupec datum vytvoreni (datIns), ostatni role maji mit sloupec datIns.
                    if (hasUserRole(UserRole.ROLE_USER) && !hasUserRole(UserRole.ROLE_MASTER) && !hasUserRole(UserRole.ROLE_ADMIN)) {
                        const dataWithoutDatIns = [_.omit(dataForExport[0], "datIns")];
                        const fieldsWithoutDatIns = _.omit(fields, "datIns")
                        exportJsonToXls(dataWithoutDatIns, fileName, fieldsWithoutDatIns)
                    } else {
                        exportJsonToXls(dataForExport, fileName, fields)
                    }
                } else {
                    let {headers, values} = extendedData;
                    // pridan if dle #4577 - kdyz ma zakaznik pouze roli uzivatel, tak nema mit v exportu sloupec datum vytvoreni (datIns), ostatni role maji mit sloupec datIns.
                    if (hasUserRole(UserRole.ROLE_USER) && !hasUserRole(UserRole.ROLE_MASTER) && !hasUserRole(UserRole.ROLE_ADMIN)) {
                        const dataWithoutDatIns = [_.omit(dataForExport[0], "datIns")];
                        const fieldsWithoutDatIns = _.omit(fields, "datIns")
                        exportJsonToXls([{...dataWithoutDatIns[0], ...values}], fileName, {...fieldsWithoutDatIns, ...headers})
                    } else {
                        exportJsonToXls([{...dataForExport[0], ...values}], fileName, {...fields, ...headers})
                    }

                }
            } else if (exportType === 'csv') {

                if (extendedData) {
                    let {headers, values} = extendedData;
                    // pridan if dle #4577 - kdyz ma zakaznik pouze roli uzivatel, tak nema mit v exportu sloupec datum vytvoreni (datIns), ostatni role maji mit sloupec datIns.
                    if (hasUserRole(UserRole.ROLE_USER) && !hasUserRole(UserRole.ROLE_MASTER) && !hasUserRole(UserRole.ROLE_ADMIN)) {
                        const dataWithoutDatIns = [_.omit(dataForExport[0], "datIns")];
                        const fieldsWithoutDatIns = _.omit(fields, "datIns")

                        exportFromJSON({
                            data: [{...dataWithoutDatIns[0], ...values}],
                            fileName,
                            exportType,
                            fields: {...fieldsWithoutDatIns, ...headers},
                            delimiter: ';',
                            withBOM: true
                        });

                    } else {
                        exportFromJSON({
                            data: [{...dataForExport[0], ...values}],
                            fileName,
                            exportType,
                            fields: {...fields, ...headers},
                            delimiter: ';',
                            withBOM: true
                        });
                    }
                } else {
                    // pridan if dle #4577 - kdyz ma zakaznik pouze roli uzivatel, tak nema mit v exportu sloupec datum vytvoreni (datIns), ostatni role maji mit sloupec datIns.
                    if (hasUserRole(UserRole.ROLE_USER) && !hasUserRole(UserRole.ROLE_MASTER) && !hasUserRole(UserRole.ROLE_ADMIN)) {
                        const dataWithoutDatIns = [_.omit(dataForExport[0], "datIns")];
                        const fieldsWithoutDatIns = _.omit(fields, "datIns")

                        exportFromJSON({
                            data: dataWithoutDatIns,
                            fileName,
                            exportType,
                            fields: fieldsWithoutDatIns,
                            delimiter: ';',
                            withBOM: true
                        });

                    } else {
                        exportFromJSON({
                            data: dataForExport,
                            fileName,
                            exportType,
                            fields: fields,
                            delimiter: ';',
                            withBOM: true
                        });
                    }
                }
            } else if (exportType === 'pdf') {
                exportToPdf(dataForExport, fileName, fields, exportConfig.detailTitle, filteredData, extendedData);
            } else {
                exportFromJSON({data: dataForExport, fileName, exportType});
            }
            return true;
        }
        return false;
    };

    return {exportData, getFilterData}
}

const ExportProgress = ({exportAll}: { exportAll?: boolean }) => {
    const [progress, setProgress] = useState(0);

    const handler = (token?: string | symbol, message?: string) => {
        if (token === 'updateProgress') {
            setProgress(Number(message));
        }
    }

    const {unsubscribe, resubscribe} = useSubscribe({token: 'updateProgress', handler})

    useEffect(() => {
        if (exportAll) resubscribe();
        return () => {
            if (exportAll) unsubscribe();
        }
        // eslint-disable-next-line
    }, [])

    return <Box sx={{position: 'relative', display: 'inline-flex'}}>
        <CircularProgress size={25} color={"inherit"}/>
        {exportAll ? <Box
            sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="caption" component="div" color="text.secondary" fontSize={8}>
                {`${Math.round(progress === 100 ? progress - 1 : progress)}%`}
            </Typography>
        </Box> : null}
    </Box>
}

export const ExportMenu = (props: MTToolbarProps & {
    exportAll?: boolean,
    menuRef?: MutableRefObject<MTMultiColumnActionButtonsExposed>
}) => {
    const [columnsButtonAnchorEl, setColumnsButtonAnchorEl] = useState<HTMLButtonElement>();
    const {exportData, getFilterData} = useExportData(props.exportConfig, props.exportAll, props.getFilter);
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const formats = props.exportAll ? props.exportConfig?.exportAllFormats ? props.exportConfig?.exportAllFormats : props.exportConfig.formats : props.exportConfig.formats;
    const [allowExport, setAllowExport] = useState(false);
    const [dataToPrint, setDataToPrint] = useState(null);

    useImperativeHandle(props.menuRef, () => ({
        selectedCountChanged
    }));

    useEffect(() => {
        if (dataToPrint !== null) {
            setDataToPrint(null);
        }
    }, [dataToPrint]);

    const selectedCountChanged = (count: number) => {
        setAllowExport(count !== 0)
    };

    return <>
        {<MTPrintTable
            data={dataToPrint}
        />}
        {(props.showSelect || props.exportAll) && !(props.showSelect && props.exportAll) ?
            <MTToolbarButton
                icon={isLoading ? <div
                    style={{width: 48, height: 48, display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <ExportProgress exportAll={props.exportAll}/></div> : !props.exportAll ? <FileDownloadIcon/> :
                    <PlaylistAddCheckIcon/>}
                tooltip={!props.exportAll ? t("MaterialTable.export") : !isObjectEmpty(getFilterData()) ? t("MaterialTable.exportFilter") : t("MaterialTable.exportAll")}
                color={"primary"}
                disabled={(!props.exportAll && !allowExport) || isLoading}
                onClick={(event) => {
                    setColumnsButtonAnchorEl(event.currentTarget)
                }}
            />

            : null}
        <Menu
            anchorEl={columnsButtonAnchorEl}
            open={Boolean(columnsButtonAnchorEl)}
            onClose={() => setColumnsButtonAnchorEl(null)}
            style={{marginTop: '3rem'}}>

            {formats.some(f => f === 'pdf') ? <MenuItem onClick={async () => {
                setColumnsButtonAnchorEl(null);
                setIsLoading(true);
                await exportData(props.data, 'pdf');
                setIsLoading(false);
            }}
            ><span>{t('MaterialTable.exportPdf')}</span></MenuItem> : null}

            {formats.some(f => f === 'xls') ? <MenuItem onClick={async () => {
                setColumnsButtonAnchorEl(null);
                setIsLoading(true);
                await exportData(props.data, exportFromJSON.types.xls);
                setIsLoading(false);
            }}
            ><span>{t('MaterialTable.exportXls')}</span></MenuItem> : null}

            {formats.some(f => f === 'csv') ? <MenuItem onClick={async () => {
                setColumnsButtonAnchorEl(null);
                setIsLoading(true);
                await exportData(props.data, exportFromJSON.types.csv);
                setIsLoading(false);
            }}
            ><span>{t('MaterialTable.exportCsv')}</span></MenuItem> : null}

            {formats.some(f => f === 'xml') ? <MenuItem onClick={async () => {
                setColumnsButtonAnchorEl(null);
                setIsLoading(true);
                await exportData(props.data, exportFromJSON.types.xml);
                setIsLoading(false);
            }}
            ><span>{t('MaterialTable.exportXml')}</span></MenuItem> : null}

            {formats.some(f => f === 'print') ? <MenuItem onClick={async () => {
                setColumnsButtonAnchorEl(null);
                setIsLoading(true);
                setDataToPrint(await exportData(props.data, "print"));
                setIsLoading(false);
            }}
            ><span>{t('MaterialTable.exportPrint')}</span></MenuItem> : null}
        </Menu>
    </>
}

export type ExportButtonsProps = {
    exportConfig?: ExportConfig
    data: any
}

export const ExportButtons = (props: ExportButtonsProps) => {
    const {t} = useTranslation();
    const {exportData} = useExportData(props.exportConfig);

    return props.exportConfig?.exportable && props.exportConfig?.exportDetailOnly ?
        <>
            {props.exportConfig?.formats?.some(f => f === 'pdf') ? <Grid item>
                <Button variant={"contained"} color={"primary"} onClick={async () => {
                    if (props.exportConfig.extendedDataEndpoint) {
                        let extendedDataEndpoint = props.exportConfig.extendedDataEndpoint(props.data);
                        extendedDataEndpoint.then(async (result) => {
                            await exportData(props.data, 'pdf', true, result.data)
                        }).catch(error => {
                            console.error('Error fetching data:', error);
                        });
                    } else {
                        await exportData(props.data, 'pdf', true)
                    }
                }}>{t("MaterialTable.exportPdf")}</Button>
            </Grid> : null}
            {props.exportConfig?.formats?.some(f => f === 'xls') ? <Grid item>
                <Button variant={"contained"} color={"primary"} onClick={async () => {
                    if (props.exportConfig.extendedDataEndpoint) {
                        let extendedDataEndpoint = props.exportConfig.extendedDataEndpoint(props.data);
                        extendedDataEndpoint.then(async (result) => {
                            await exportData(props.data, exportFromJSON.types.xls, true, props.exportConfig.extendedDataMapper(result.data))
                        }).catch(error => {
                            console.error('Error fetching data:', error);
                        });
                    } else {
                        await exportData(props.data, exportFromJSON.types.xls, true)
                    }
                }}>{t("MaterialTable.exportXls")}</Button>
            </Grid> : null}
            {props.exportConfig?.formats?.some(f => f === 'csv') ? <Grid item>
                <Button variant={"contained"} color={"primary"} onClick={async () => {
                    if (props.exportConfig.extendedDataEndpoint) {
                        let extendedDataEndpoint = props.exportConfig.extendedDataEndpoint(props.data);
                        extendedDataEndpoint.then(async (result) => {
                            await exportData(props.data, exportFromJSON.types.csv, true, props.exportConfig.extendedDataMapper(result.data))
                        }).catch(error => {
                            console.error('Error fetching data:', error);
                        });
                    } else {
                        await exportData(props.data, exportFromJSON.types.csv, true)
                    }
                }}>{t("MaterialTable.exportCsv")}</Button>
            </Grid> : null}
        </> : null
}
