import * as React from "react";
import {useRef, useState} from "react";
import {FormInputType} from "../../../../raal_components/form/Form";
import {Dial} from "../../../../raal_components/dial/Dial";
import {useTranslation} from "react-i18next";
import {Preprava} from "../../../../model/Preprava";
import {
    exportableFieldsAdminView, exportableFieldsView,
    getExportableProps,
    getFilterVersion,
    useVPPdfLayout
} from "../_vp/PrepravaAVozidlaShared";
import {VPFilter, VPList, VPListPrepravaAdmin, VPListVozidloAdmin} from "../../../../model/PrepravaVozidlo";
import {useViewColumns} from "../_vp/VPColumns";
import {FilterForm} from "../_vp/VPFilter";
import {ExtendedViewNew} from "../_vp/VPExtendedView";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {useEnumCiselnikSelect, useEnumeratedSelect, useYesNoSelect} from "../../../../raal_components/SelectOptions";
import {useCiselnikValues, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {CheckMark} from "../../../../../common/component/CheckMark";
import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {TFunction} from "i18next";
import {SelectProps} from "../../../../../common/component/form/FormSelect";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {globalStyles} from "../../../../context/ThemeModeContext";
import {InvalDuvodPreprava, PrepravaSearchEntityType} from "../../../../model/CommonTypes";
import {NabidkaType} from "../../../../model/NabidkaType";
import {useAppContext} from "../../../../context/AppContext";
import {FormDateRange} from "../../../../../common/component/form/FormDateRange";
import {
    CISELNIK_DRUH,
    DEFAULT_ADVANCE_VALUE,
    DEFAULT_RANGE_VALUE,
    PARAM_ADVANCE_KEY,
    PARAM_RANGE_KEY
} from "../prohlizeni/PrepravyCiselnikView";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {RouteComponentProps} from "../../../../routes";
import {getEntityTypeUrl} from "./SearchFunctions";

export const prepareCols = (createColumns: () => Column<VPList>[], t: TFunction, searchTypeSelect: SelectProps, yesNoSelect: SelectProps, locFun: (locKey: string, en: any, code: any) => string , invalDuvSelect: { formatOption: (value: any) => { label: string; value: any }; formatValue: (value: any) => any; options: string[]}, preprava?: boolean) => {
    let addCols = createColumns() as any;

    addCols.push(
            {
                title: 'ID',
                field: 'id',
                render: (data: VPListPrepravaAdmin) => data.id,
                filterProps: () => ({type: FormInputType.Text, name: 'id'})
            },
            {
                title: t("PVI.Valid"),
                field: 'valid',
                render: (data: VPListPrepravaAdmin) => <CheckMark checked={data.valid}/>,
                filterProps: () => ({type: FormInputType.Select, selectProps: yesNoSelect})
            },
            {
                title: t("PVI.InvalDuv"),
                field: 'invalDuv',
                render: (row: VPListPrepravaAdmin) => locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, row.invalDuv),
                filterProps: () => ({type: FormInputType.Select, selectProps: invalDuvSelect}),
                cellStyle: {minWidth: 180},
                headerStyle: {minWidth: 180}
            },
            {
                title: t("User.Title"),
                field: 'uzivatelText',
                render:(data:VPListPrepravaAdmin)=> data.uzivatel?.toString() ?? "",
                filterProps: () => ({type: FormInputType.Text, name: "uzivatelText"}),
                cellStyle: {minWidth: 180},
                headerStyle: {minWidth: 180}
            },
            {
                title: t("Archive.Archived"),
                field: 'deletedOn',
                render: (data: VPListPrepravaAdmin) => (data.deletedOn)?.format("L LT") ?? "",
                defaultSort: "desc",
                filterProps: () => ({
                    type: FormInputType.Custom,
                    customComponent: FormDateRange,
                    customComponentOptions: {timeFormat: false},
                    name: 'deletedOnRange'
                }),
                cellStyle: {minWidth: 150},
                headerStyle: {minWidth: 150},
            },
            {
                title: t("SearchEnum.entityType"),
                field: 'entityType',
                filterProps: () => ({type: FormInputType.Select, selectProps: searchTypeSelect}),
                render:(data: VPListPrepravaAdmin | VPListVozidloAdmin) => preprava ? t(`SearchEnum.Preprava.${data.entityType}`) : t(`SearchEnum.VolneVozy.${data.entityType}`)
            }
        );

    return addCols;
}

export function PrepravyCiselnikSearch(props: RouteComponentProps) {
    const {t} = useTranslation();
    const {user} = useAppContext();
    const [createColumns] = useViewColumns(CISELNIK_DRUH, false, undefined, undefined, ["datIns", "uzivatel"], PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, true, {search: true, typ: NabidkaType.PREPRAVA, refactor: true}, true);
    const dateKey = "preprava-search-browse-date";
    const endpoint = "admin/preprava-search-union";
    const invalDuvSelect = useEnumCiselnikSelect({isClearable: true, ciselnikTyp: CiselnikTyp.P, enm: InvalDuvodPreprava, enmName: "Enumerations.InvalDuvodPreprava"});
    const yesNoSelect = useYesNoSelect({isClearable:true});
    const searchTypeSelect = useEnumeratedSelect(PrepravaSearchEntityType, "SearchEnum.Preprava", "string", {isClearable:true});
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const dtGrid = useRef<DataGridExposed<VPListPrepravaAdmin, VPFilter>>();
    const [cols] = useState(prepareCols(createColumns, t, searchTypeSelect, yesNoSelect, locFun, invalDuvSelect, true));
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {pdfLayout} = useVPPdfLayout(user);


    return <Dial<VPListPrepravaAdmin, VPFilter, Preprava>
        mode={props.mode}
        lastBrowsedDateKey={dateKey}
        logActivity
        gridRef={dtGrid}
        config={{
            lockSupport: {enabled: false},
            getDetailIdUrlPart: (data) : string =>
                (`${getEntityTypeUrl(data.entityType)}${data.id}`),
            overflowHidden: true,
            exportConfig: {
                exportable: true,
                exportAll: true,
                endpoint: endpoint,
                getExportDetailIdUrlPart: (data) =>
                    ({endpoint: getEntityTypeUrl(data.entityType), id: data.id}),
                fileName: "export_vyhledavani_prepravy",
                translationPrefix: ['Preprava', 'User', 'Archive'],
                formats: ["pdf", "xls", "csv", "xml"],
                exportAllFormats: ["csv"],
                exportableProps: getExportableProps(exportableFieldsAdminView, true),
                pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                extendedProps: [
                    {
                        type: ["csv", "xls"],
                        addExtendedProps: (data) => {
                            return {
                                provozovna: data.uzivatel?.provozovna?.kod,
                                archived: data.deletedOn
                            }
                        }
                    }
                 ],
                formattedProps: [
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "druhy",
                        format: (data) => {
                            return druhyJoined(data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "invalDuv",
                        format: (data) => {
                            return locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, data)
                        }
                    },
                    {
                        type: ["csv", "xls", "pdf"],
                        field: "rychlyKontakt",
                        format: (data) => {
                            return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                        }
                    }
                ]
            },
            options: {
                rowStyle: (data: any) => {
                    if (!data.valid)
                        return globalStyles.rowStyleAlert;
                    if(data.stav===InvalDuvodPreprava.DUPLICITA){
                        return globalStyles.rowStyleDuplicated;
                    }
                    return undefined;
                }
            },
            version:getFilterVersion(),
            tableTitle: t("Dials.Prepravy"), endpoint: endpoint, clazz: VPListPrepravaAdmin, filtering: true,
            hideAddNewActions: true,
            columns: cols,
            hideDefaultDummyAction:false,
        }}
        hideSaveButton
        hideNewButtonOnEdit
        clazzDetail={Preprava}
        crudConfig={{editEnabled: false, addEnabled: false, removeEnabled: false}}
        layoutFilter={() => <FilterForm {...{lastBrowseDateDataKey: dateKey, typCiselniku:CISELNIK_DRUH, preprava: true, viewing: true, defaultRange:DEFAULT_RANGE_VALUE, typAdvance: PARAM_ADVANCE_KEY, defaultAdvance: DEFAULT_ADVANCE_VALUE, search: true}}/>}
        layoutDetail={()=><TabHelperNew<Preprava> render={(data)=>
            <>
                <ExtendedViewNew ciselnikTyp={CISELNIK_DRUH} data={data} admin/>
            </>}
        />}
        filterClazz={VPFilter}
    />;
}
